import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { isNull, isUndefined } from 'lodash';
import React, { FC, memo, useMemo } from 'react';
import AppHeader from '../components/AppHeader';
import useAuth from '../hooks/useAuth';
import SignIn from './unprotected/SignIn';
import SignUp from './unprotected/SignUp';
import SplashScreen from './unprotected/SplashScreen';
import ProtectedTabNavigator from './protected'
import ResetPassword from './unprotected/ResetPassword';

export type RootStackParamList = {
  splashscreen: undefined;
  signin: undefined;
  signup: undefined;
  resetpassword: undefined;
  protected: undefined
};

const Stack = createNativeStackNavigator<RootStackParamList>()

const NavigationContainerManager: FC = () => {
  const {user} = useAuth()

  const appName = useMemo<string>(() => {
    return 'BrasilAmericano Admin'
  }, [])

  const linking = useMemo(() => {
    return {
      prefixes: ['https://brasilamericano.com/admin'],
      config: {
        screens: {
          splashscreen: '',
          signin: 'sign-in',
          signup: 'cadastro',
          resetpassword: 'redefinir-senha',
          protected: {
            path: 'protected',
            screens: {
              dashboard: {
                path: 'dashboard',
                screens: {
                  home: '',
                  newitem: 'novo-servico',
                  edititem: 'editar-servico/:id',
                  itemstatistics: 'estatisticas/:id'
                }
              },
              users: {
                path: 'usuarios',
                screens: {
                  list: ''
                }
              },
              functions: {
                path: 'funcoes',
                screens: {
                  dashboard: ''
                }
              }
            }
          }
        }
      }
    }
  }, [])

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{
        header: (props) => <AppHeader {...props} />
      }}>
        {isUndefined(user) ? (
          <Stack.Screen name="splashscreen" component={SplashScreen} options={{
            title: appName,
            headerShown: false,
          }} />
        ) : isNull(user) ? (
          <>
            <Stack.Screen name="signin" component={SignIn} options={{
              title: `${appName} | Entrar`,
              headerShown: false,
            }} />
            <Stack.Screen name="signup" component={SignUp} options={{
              title: `${appName} | Cadastrar`,
              headerShown: false,
            }} />
            <Stack.Screen name="resetpassword" component={ResetPassword} options={{
              title: `${appName} | Redefinir Senha`,
              headerShown: false,
            }} />
          </>
        ) : (
          <Stack.Screen name="protected" component={ProtectedTabNavigator} options={{
            title: `${appName} | Dashboard`,
            headerShown: false,
          }} />
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default memo(NavigationContainerManager);
