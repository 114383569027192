import { ItemMode } from './../models/Item.class';
import { FirestoreCollections } from './../models/FirestoreCollections.enum';
import { getFirestore, collection, CollectionReference, doc, DocumentSnapshot, getDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import Item from '../models/Item.class';
import Permission from '../models/Permission.class';
import { User } from '../models/User.class';
import moment from 'moment';

const useItemPermission = (itemId?: string | null, userData?: User | null, itemMode: ItemMode = ItemMode.ITEM) => {
  const firestore = getFirestore();

  const [permission, setPermission] = useState<Permission>()

  useEffect(() => {
    if (!firestore || !itemId || !userData) {
      setPermission(undefined)
      return
    }

    const permissionRef = doc(collection(firestore, userData.snapshot.ref.path, FirestoreCollections.PERMISSIONS) as CollectionReference<Permission>, itemId)

    if (['admin', 'mod'].includes(userData.role)) {
      const adminAppPermissionDoc = {
        id: itemId,
        ref: permissionRef,
        data: () => ({
          itemRef: doc(collection(firestore, itemMode === ItemMode.ITEM ? FirestoreCollections.ITEMS : itemMode === ItemMode.CHANGE ? FirestoreCollections.REQUESTED_CHANGES : FirestoreCollections.ITEMS_FOR_APPROVAL) as CollectionReference<Item>, itemId),
          userRef: userData.snapshot.ref,
          level: 'owner',
          createdAt: moment().utc().toDate()
        })
      } as unknown as DocumentSnapshot<Permission>

      setPermission(new Permission(adminAppPermissionDoc))
      return
    }

    if (['change', 'item'].includes(itemMode)) {
      (async () => {
        const permissionDoc = await getDoc(permissionRef)
  
        if (!permissionDoc.exists()) {
          setPermission(undefined)
          return
        }
  
        setPermission(new Permission(permissionDoc))
      })()
      return
    }

    if (itemMode === 'new') {
      ;(async () => {
        const itemRef = doc(collection(firestore, FirestoreCollections.ITEMS_FOR_APPROVAL) as CollectionReference<Item>, itemId)
        const itemDoc = await getDoc(itemRef)

        if (!itemDoc.exists()) {
          setPermission(undefined)
          return
        }

        setPermission(new Permission({
          id: itemId,
          ref: permissionRef,
          data: () => ({
            itemRef,
            userRef: userData.snapshot.ref,
            level: 'owner',
            createdAt: moment().utc().toDate()
          })
        } as unknown as DocumentSnapshot<Permission>))
      })()
    }
  }, [itemId, userData, firestore, itemMode])

  return { permission }
}

export default useItemPermission
