import { isNil } from 'lodash'
import { Category } from '../models/Category.class'
import City, { CityRef } from '../models/City.class'
import Item from '../models/Item.class'
import State, { StateRef } from '../models/State.class'
import { NewItem } from '../views/protected/views/NewItemPage'

export const PASSWORD_MIN_LENGTH = 6

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i // eslint-disable-line no-useless-escape
  const result = email.toLowerCase().match(emailRegex)
  return !!result && result.length > 0
}

export const isValidInput = (text: string, minLength: number = 0): boolean => {
  return !isNil(text) && text.trim().length >= minLength
}

export const isValidPassword = (password: string): boolean => isValidInput(password, PASSWORD_MIN_LENGTH)

export const isValidAuthCredentials = (email: string, password: string): boolean => {
  return isValidEmail(email) && isValidInput(password, PASSWORD_MIN_LENGTH)
}

export const isValidSignUpCredentials = (params: {
  email: string,
  password: string,
  confirmPassword: string,
}): boolean => {
  return (
    isValidEmail(params.email) &&
    isValidPassword(params.password) &&
    isValidPassword(params.confirmPassword) &&
    params.password === params.confirmPassword
  )
}

export const isValidState = (address?: string | null, state?: State | StateRef | null): boolean => {
  return (!isNil(address) && address.trim().length > 0 && !isNil(state))
          || isNil(address)
          || (!isNil(address) && address.trim().length === 0)
}

export const isValidCity = (selectedCityId: string, address?: string | null, city?: City | CityRef | null, newCityName?: string | null): boolean => {
  return (
      (!isNil(address) && address.trim().length > 0 
        && (
          !isNil(city) 
          || (
            !isNil(newCityName) 
            && newCityName.trim().length > 0
          )
        )
      )
      || isNil(address)
      || (!isNil(address) && address.trim().length === 0)
    )
    && (
      selectedCityId !== 'other'
      || (
        selectedCityId === 'other'
        && !isNil(newCityName)
        && newCityName.trim().length > 0
      )
    )
}

export const isValidNewItem = (newItem: NewItem, selectedCityId: string, category?: Category, state?: State, city?: City, newCityName?: string): boolean => {
  return (
    isValidInput(newItem.name, 1) &&
    (!newItem.email || newItem.email.length === 0 || isValidEmail(newItem.email)) &&
    !isNil(category) &&
    isValidState(newItem.address1, state) && 
    isValidCity(selectedCityId, newItem.address1, city, newCityName)
  )
}

export const isValidItem = (item: Item): boolean => {
  return (
    isValidInput(item.name, 1) &&
    (!item.email || item.email.length === 0 || isValidEmail(item.email)) &&
    !isNil(item.category) &&
    isValidState(item.address1, item.state) && 
    isValidCity('', item.address1, item.city)
  )
}
