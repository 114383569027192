import { getStateCoords } from './../helpers/coords.helper';
import { collection, CollectionReference, DocumentReference, DocumentSnapshot, Firestore, GeoPoint, getDoc, getDocs, orderBy, query, runTransaction } from "firebase/firestore";
import City from "./City.class";
import { FirestoreSnapshot } from "./FirestoreSnapshot.class";
import * as Analytics from 'expo-firebase-analytics'
import { getGeoHash } from "../helpers/coords.helper";

export interface StateRef {
  ref: DocumentReference<State>
  name: string
  acronym: string
}

export default class State extends FirestoreSnapshot<State> {
  name: string
  acronym: string
  coords?: GeoPoint | null
  geoHash?: string | null

  cities?: City[]

  constructor(doc: DocumentSnapshot<State>) {
    super(doc)

    const data = doc.data()!

    this.name = data.name
    this.acronym = data.acronym
    this.coords = data.coords
    this.geoHash = data.geoHash
  }

  async fetchCities(): Promise<City[]> {
    this.cities = []

    try {
      const result = await getDocs(query(collection(this.snapshot.ref.firestore, `${this.snapshot.ref.path}/cities`) as CollectionReference<City>, orderBy('name', 'asc')))
      result.forEach(doc => this.cities?.push(new City(doc)))
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('state_error', {
          method: 'fetchCities',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.cities
  }
  
  async getCoords(): Promise<GeoPoint | null> {
    if (this.coords) {
      return this.coords
    }
    
    try {
      this.coords = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          console.error('state - getCoords - not found')
          return null
        }

        const newCoords = await getStateCoords(this.name)
        await transaction.update(this.snapshot.ref, {
          coords: newCoords,
        })

        return newCoords
      })
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('state_error', {
          method: 'state_getCoords',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      return null
    }
  }

  async updateGeoHash(): Promise<string | null | undefined> {
    if (this.geoHash) {
      return this.geoHash
    }
    
    if (!this.coords) {
      return null
    }

    try {
      this.geoHash = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          console.error('state - updateGeoHash - not found')
          return this.geoHash
        }

        const newGeoHash = getGeoHash(this.coords!)
        await transaction.update(this.snapshot.ref, {
          geoHash: newGeoHash,
        })

        return newGeoHash
      })
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('state_error', {
          method: 'state_updateGeoHash',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      return this.geoHash
    }
  }
}
