import { FormControl, Select, theme, CheckIcon, Icon } from 'native-base';
import { FC, memo } from 'react';
import { SimpleLineIcons } from '@expo/vector-icons';
import State from '../../models/State.class';

interface SelectStateProps {
  isRequired: boolean;
  isInvalid: boolean;
  selectedValue: string;
  setSelectedStateId: (value: string) => void;
  states: State[] | undefined;
}

const SelectState: FC<SelectStateProps> = ({isRequired, isInvalid, selectedValue, setSelectedStateId, states}) => {
  return (
    <FormControl isRequired={isRequired} flex={1} isInvalid={isInvalid}>
      <FormControl.Label>Estado</FormControl.Label>
      <Select
        variant='rounded'
        selectedValue={selectedValue}
        flex={1}
        onValueChange={itemValue => setSelectedStateId(itemValue)}
        _selectedItem={{
          bg: theme.colors.primary[800],
          leftIcon: <CheckIcon size={2} />
        }}
        dropdownIcon={
          <Icon
            size={3}
            color={theme.colors.primary[800]}
            as={SimpleLineIcons}
            name="arrow-down"
            marginRight={4}
          />
        }
      >
        <Select.Item label={'Selecionar Estado'} value={''} />
        {states ? states.map(state => (
          <Select.Item key={state.name} label={state.name} value={state.id} />
        )) : <></>}
      </Select>
      <FormControl.ErrorMessage>
        Estado é obrigatório quando um endereço for preenchido.
      </FormControl.ErrorMessage>
    </FormControl>
  );
}

export default memo(SelectState);
