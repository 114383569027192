import * as GeoFire from 'geofire-common';
import { GeoPoint } from "firebase/firestore"
import * as Location from 'expo-location'
import * as Analytics from 'expo-firebase-analytics'

export const getStateCoords = async (stateName: string): Promise<GeoPoint | null> => {
  try {
    const results = await Location.geocodeAsync(`${stateName} United States`, { useGoogleMaps: true })

    if (results.length === 0) {
      return null
    }

    return new GeoPoint(results[0].latitude, results[0].longitude)
  } catch (e) {
    console.error(e)
    
    try {
      Analytics.logEvent('getStateCoords_error', {
        method: 'getStateCoords',
        stateName,
        name: (e as Error).name ?? 'Error',
        message: (e as Error).message ?? 'none',
        code: (e as any).code ?? 'none'
      })
    } catch (e) {
      console.error(e)
    }

    return null
  }
}

export const getCityCoords = async (stateName: string, cityName: string): Promise<GeoPoint | null> => {
  try {
    const results = await Location.geocodeAsync(`${cityName} ${stateName} United States`, { useGoogleMaps: true })

    if (results.length === 0) {
      return null
    }

    return new GeoPoint(results[0].latitude, results[0].longitude)
  } catch (e) {
    console.error(e)
    
    try {
      Analytics.logEvent('getCityCoords_error', {
        method: 'getCityCoords',
        stateName,
        cityName,
        name: (e as Error).name ?? 'Error',
        message: (e as Error).message ?? 'none',
        code: (e as any).code ?? 'none'
      })
    } catch (e) {
      console.error(e)
    }

    return null
  }
}

export const getCoords = async (selectedStateName: string, address1: string, address2: string, zipCode: string, cityName: string): Promise<GeoPoint | null> => {
  if (!address1 || address1.length === 0 || !selectedStateName || selectedStateName.length === 0) {
    return null
  }

  let fullAddress = address1

  if (address2 && address2.length > 0) {
    fullAddress += ' ' + address2
  }

  fullAddress += ' ' + cityName

  fullAddress += ' ' + selectedStateName

  if (zipCode && zipCode.length > 0) {
    fullAddress += ' ' + zipCode
  }

  fullAddress += ' United States'
  
  try {
    const results = await Location.geocodeAsync(fullAddress, { useGoogleMaps: true })

    if (results.length === 0) {
      return null
    }

    return new GeoPoint(results[0].latitude, results[0].longitude)
  } catch (e) {
    console.error(e)
    
    try {
      Analytics.logEvent('getCoords_error', {
        method: 'getCoords',
        selectedStateName,
        address1,
        address2,
        zipCode,
        cityName,
        name: (e as Error).name ?? 'Error',
        message: (e as Error).message ?? 'none',
        code: (e as any).code ?? 'none'
      })
    } catch (e) {
      console.error(e)
    }

    return null
  }
}

export const getGeoHash = (coords: GeoPoint | null): string | null => {
  if (!coords) {
    return null
  }

  return GeoFire.geohashForLocation([coords.latitude, coords.longitude])
}
