import { isNil } from 'lodash';
import { getGeoHash, getCoords as getItemCoords } from './../helpers/coords.helper';
import { collection, CollectionReference, DocumentSnapshot, GeoPoint, getDocs, query, where, runTransaction, DocumentReference } from "firebase/firestore";
import { CategoryRef, SubCategory } from "./Category.class";
import { CityRef } from "./City.class";
import ContactMember from "./ContactMember.class";
import { FirestoreSnapshot } from "./FirestoreSnapshot.class";
import { StateRef } from "./State.class";
import * as Analytics from 'expo-firebase-analytics'
import Permission from './Permission.class'
import moment from 'moment';

export interface ItemCoupon {
  name: string
  amount: number
  prefix?: string | null
  suffix?: string | null
  active: boolean
  createdAt: Date
  deletedAt?: Date | null
}

export enum ItemStatus {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  PENDING_APPROVAL = 'Em análise',
  REJECTED = 'Reprovado'
}

export enum ItemMode {
  ITEM = 'item',
  NEW = 'new',
  CHANGE = 'change',
}

export default class Item extends FirestoreSnapshot<Item> {
  name: string
  nameLowerCase?: string | null
  description: string
  active: boolean
  waitingForApproval: boolean
  rejectedReason?: string | null
  relevance: number
  type?: string | null
  model?: string | null
  authorId?: string | null

  logo?: string | null
  banner?: string | null
  slides: string[]
 
  phone?: string | null
  sms?: string | null
  whatsapp?: string | null
  email?: string | null
  website?: string | null

  facebook?: string | null
  instagram?: string | null
  linktree?: string | null
  tiktok?: string | null
  linkedin?: string | null
  youtube?: string | null

  address1?: string | null
  address2?: string | null
  zipCode?: string | null
  coords: GeoPoint | null
  geoHash?: string | null

  createdAt: Date
  updatedAt?: Date
  deletedAt?: Date
  
  state: StateRef | null
  city: CityRef | null
  category: CategoryRef
  subCategories?: SubCategory[]

  members?: ContactMember[]
  membersQuantity: number

  addressLine: string = ''

  permissions?: Permission[] | null

  coupons?: ItemCoupon[] | null

  status: ItemStatus

  mode: ItemMode

  verified: boolean

  constructor(doc: DocumentSnapshot<Item>, mode: ItemMode = ItemMode.ITEM) {
    super(doc)
    
    const data = doc.data()!
    
    this.name = data.name
    this.nameLowerCase = data.nameLowerCase ?? data.name.toLowerCase()
    this.description = data.description
    this.type = data.type
    this.model = data.model
    this.active = data.active
    this.relevance = data.relevance ?? 0
    this.rejectedReason = data.rejectedReason
    this.waitingForApproval = data.waitingForApproval ?? false
    this.authorId = data.authorId
    
    this.logo = data.logo
    this.banner = data.banner
    this.slides = data.slides ?? []

    this.phone = data.phone
    this.sms = data.sms
    this.whatsapp = data.whatsapp
    this.email = data.email
    this.website = data.website

    this.facebook = data.facebook
    this.instagram = data.instagram
    this.linktree = data.linktree
    this.tiktok = data.tiktok
    this.linkedin = data.linkedin
    this.youtube = data.youtube

    this.address1 = data.address1
    this.address2 = data.address2
    this.zipCode = data.zipCode
    this.coords = data.coords
    this.geoHash = data.geoHash

    this.state = data.state
    this.city = data.city
    this.category = data.category
    this.subCategories = data.subCategories
    this.membersQuantity = data.membersQuantity ?? 0
    this.permissions = data.permissions
    this.coupons = data.coupons

    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.deletedAt = data.deletedAt

    this.verified = data.verified ?? false

    if (this.address1) {
      this.addressLine = `${this.address1}${this.address2 && this.address2.length > 0 ? `, ${this.address2}` : ''}, ${this.city?.name}, ${this.state?.name}${this.zipCode && this.zipCode.length > 0 ? ` ${this.zipCode}` : ''}`
    }

    this.status = this.getStatus()
    this.mode = mode
  }

  getStatus(): ItemStatus {
    if (this.active) {
      return ItemStatus.ACTIVE
    } else if (this.rejectedReason && this.rejectedReason.trim().length > 0) {
      return ItemStatus.REJECTED
    } else if (this.waitingForApproval) {
      return ItemStatus.PENDING_APPROVAL
    }

    return ItemStatus.INACTIVE
  }

  async fetchMembers(): Promise<ContactMember[]> {
    this.members = []
    
    try {
      const result = await getDocs(query(collection(this.snapshot.ref.firestore, `${this.snapshot.ref.path}/members`) as CollectionReference<ContactMember>, where('active', '==', true)))
      
      result.forEach(doc => this.members?.push(new ContactMember(doc)))
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('item_error', {
          method: 'fetchMembers',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.members
  }

  async fetchPermissions(): Promise<Permission[]> {
    this.permissions = []
    
    try {
      const result = await getDocs(query(collection(this.snapshot.ref.firestore, `${this.snapshot.ref.path}/permissions`) as CollectionReference<Permission>))
      
      result.forEach(doc => this.permissions?.push(new Permission(doc)))
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('item_error', {
          method: 'fetchPermissions',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.permissions
  }

  async increaseRelevance(): Promise<number> {
    try {
      this.relevance = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          console.error('increaseRelevance - not found')
          return this.relevance
        }

        const newRelevance = doc.data().relevance + 1
        await transaction.update(this.snapshot.ref, {
          relevance: newRelevance,
          updatedAt: moment().utc().toDate(),
        })

        return newRelevance
      })
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('item_error', {
          method: 'increaseRelevance',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.relevance
  }

  async getCoords(): Promise<GeoPoint | null> {
    if (this.coords) {
      return this.coords
    }

    if (!this.state || isNil(this.address1) || this.address1.trim().length === 0) {
      return null
    }

    try {
      this.coords = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          console.error('item - getCoords - not found')
          return null
        }

        const newCoords = await getItemCoords(this.state?.name ?? '', this.address1 ?? '', this.address2 ?? '', this.zipCode ?? '', this.city?.name ?? '')
        await transaction.update(this.snapshot.ref, {
          coords: newCoords,
        })

        return newCoords
      })
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('item_error', {
          method: 'item_getCoords',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      return null
    }
  }

  async updateGeoHash(): Promise<string | null | undefined> {
    try {
      this.geoHash = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          console.error('updateGeoHash - not found')
          return this.geoHash
        }

        const newGeoHash = getGeoHash(this.coords)
        await transaction.update(this.snapshot.ref, {
          geoHash: newGeoHash,
          updatedAt: moment().utc().toDate(),
        })

        return newGeoHash
      })
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('item_error', {
          method: 'updateGeoHash',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      return this.geoHash
    }
  }
}
