import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../../../components/AppHeader';
import Users from '../views/Users';

export type UsersStackParamList = {
  list: undefined;
  userItems: {
    id: string
  }
};

const Stack = createNativeStackNavigator<UsersStackParamList>()

const UsersNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='list' screenOptions={{
      header: (props) => <AppHeader {...props} />
    }}>
      <Stack.Screen name="list" component={Users} options={{
        title: 'BrasilAmericano | Usuários'
      }} />
      {/* <Stack.Screen name="userItems" component={UserItems} options={{
        title: 'BrasilAmericano | Empresas'
      }} /> */}
    </Stack.Navigator>
  )
}

export default memo(UsersNavigator)
