import React, { FC, memo, useCallback, useState } from 'react';
import { ScrollView, VStack, Image, FormControl, Input, WarningOutlineIcon, Button, useTheme, View } from 'native-base';
import useAuth from '../../hooks/useAuth';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { isValidAuthCredentials, isValidEmail, isValidInput, isValidPassword } from '../../helpers/validate.helper';
import { RootStackParamList } from '../NavigationContainerManager';

export interface SignInCredentials {
  email: string
  password: string
}

const SignIn: FC = () => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  
  const {signIn} = useAuth()

  const goToSignUp = () => {
    navigation.navigate('signup')
  }

  const goToResetPassword = () => {
    navigation.navigate('resetpassword')
  }

  const handleSignInClick = useCallback(async () => {
    setSubmitted(true)
    setLoading(true)

    if (isValidAuthCredentials(email, password)) {
      try {
        await signIn(email, password)
      } catch {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [email, password, signIn, navigation])
  
  return (
    <View flex={1} width='full' overflow='hidden'>
      <ScrollView flex={1} padding={4}>
        <VStack space={4} alignItems='center'>
          <Image source={require('../../assets/logo.png')} width={120} height={120} resizeMode='contain' />

          <FormControl isRequired isInvalid={submitted && !isValidEmail(email)}>
            <VStack>
              <Input type="text" keyboardType='email-address' autoComplete='email' variant={'rounded'} placeholder='Email' value={email} onChangeText={text => setEmail(text)} />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Por favor, insira um email válido.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={submitted && !isValidInput(password, 1)}>
            <VStack>
              <Input type="password" autoComplete='password' variant={'rounded'} placeholder='Senha' value={password} onChangeText={text => setPassword(text)} />
            </VStack>
          </FormControl>
          
          <Button isLoading={loading} isLoadingText='Entrando' width={'full'} rounded='full' onPress={handleSignInClick}>
            Entrar
          </Button>

          <Button width={'full'} rounded='full' variant='ghost' onPress={goToResetPassword} disabled={loading}>
            Esqueci minha senha
          </Button>

          <Button width={'full'} rounded='full' variant='outline' onPress={goToSignUp} disabled={loading}>
            Criar Nova Conta
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(SignIn);
