import { isUndefined } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { browserLocalPersistence, createUserWithEmailAndPassword, getAuth, setPersistence, signInWithEmailAndPassword, User as FirebaseUser, signOut as firebaseSignOut, onAuthStateChanged, AuthErrorCodes, sendPasswordResetEmail } from 'firebase/auth'
import { User } from '../models/User.class';
import { getFirestore, getDoc, doc, DocumentReference } from 'firebase/firestore';
import * as Analytics from 'expo-firebase-analytics'
import { Box, Text, useTheme, useToast } from 'native-base';
import { setPermissions } from '../redux/auth';
import { useDispatch } from 'react-redux';

const useAuth = () => {
  const auth = getAuth();
  const firestore = getFirestore()
  const toast = useToast()
  const theme = useTheme()
  const dispatch = useDispatch()

  const [user, setUser] = useState<FirebaseUser | null>()
  const [userData, setUserData] = useState<User | null>()

  const toastAuthError = useCallback((errorCode?: typeof AuthErrorCodes | null) => {
    let errorMessage = ''
    
    switch (errorCode ?? '') {
      case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      case AuthErrorCodes.EMAIL_CHANGE_NEEDS_VERIFICATION:
        errorMessage = 'Por favor, refaça o login.'
        break

      case AuthErrorCodes.UNVERIFIED_EMAIL:
        errorMessage = 'Por favor, verifique seu email. Um email de verificação foi enviado à sua conta.'
        break

      case AuthErrorCodes.USER_CANCELLED:
      case AuthErrorCodes.USER_DELETED:
      case AuthErrorCodes.USER_DISABLED:
        errorMessage = 'Ops! Sua conta foi desativada. Por favor, contate o suporte para mais informações.'
        break

      case AuthErrorCodes.INVALID_PASSWORD:
        errorMessage = 'Ops! Senha inválida. Por favor, tente novamente. Caso não se lembre de sua senha antiga, tente redefini-la.'
        break

      default:
        errorMessage = 'Ops! Um error ocorreu. Por favor, tente novamente ou contate nosso suporte.'
        break
    }

    if (toast) {
      try {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color='white'>{errorMessage}</Text>
            </Box>
          )
        })
      } catch (e) {
        alert(errorMessage)
      }
    }
  }, [toast, theme])

  const signUp = useCallback(
    async (email: string, password: string): Promise<FirebaseUser | null> => {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        
        if (userCredential) {
          setUser(userCredential.user)
          return userCredential.user
        } else {
          setUser(null)
        }

        return null
      } catch (e) {
        console.error(e);

        toastAuthError((e as any).code)
        
        try {
          Analytics.logEvent('signUp_error', {
            method: 'signUp',
            email,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
        
        throw e;
      }
    },
    [auth, toastAuthError],
  );

  const signIn = useCallback(
    async (email: string, password: string) => {
      try {
        await setPersistence(auth, browserLocalPersistence)
        const userCredential = await signInWithEmailAndPassword(auth, email, password)

        if (userCredential) {
          setUser(userCredential.user)
        } else {
          setUser(null)
        }
      } catch (e) {
        console.error(e)

        toastAuthError((e as any).code)

        try {
          Analytics.logEvent('signIn_error', {
            method: 'signIn',
            email,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
        
        throw e
      }
    },
    [auth, toastAuthError],
  );

  const signOut = useCallback(async () => {
    try {
      await firebaseSignOut(auth)
      setUser(null);
    } catch (e) {
      console.error(e);

      try {
        Analytics.logEvent('signIn_error', {
          method: 'signIn',
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [auth]);

  const resetPassword = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email)
    } catch (e) {
      console.error(e)

      toastAuthError((e as any).code)

      try {
        Analytics.logEvent('signIn_error', {
          method: 'signIn',
          email,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
      
      throw e
    }
  }, [auth])

  useEffect(() => {
    if (firestore && user && (isUndefined(userData) || (userData && userData.id === user.uid))) {
      getDoc(doc(firestore, 'users', user.uid) as DocumentReference<User>).then(result => {
        if (!result.exists()) {
          setUserData(null)
          return
        }

        dispatch(setPermissions(undefined))
        setUserData(new User(result))
      })
    } else {
      dispatch(setPermissions(undefined))
    }
  }, [user, firestore, dispatch])

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setUser(user)
    })
  }, [auth])

  return {
    user,
    userData,
    signUp,
    signIn,
    signOut,
    resetPassword
  };
};

export default useAuth;
