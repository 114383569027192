import { Box, Image, IconButton, Icon, Button } from 'native-base';
import React, { FC, memo } from 'react';
import { MaterialIcons } from '@expo/vector-icons'
import { isNil } from 'lodash';

interface EditItemBannerProps {
  banner?: string | null
  addBanner: (type: 'banner') => void,
  removeBanner: () => void,
  isLoading?: boolean
}

const EditItemBanner: FC<EditItemBannerProps> = ({
  banner,
  addBanner,
  removeBanner,
  isLoading
}) => {

  if (isNil(banner) || banner.trim().length === 0) {
    return (
      <Button isLoading={isLoading} isLoadingText='Subindo Arquivo' onPress={() => addBanner('banner')} variant='outline' rounded='md' width={'full'} height={[32, 64, 72, 96]}>
        Selecionar Banner
      </Button>
    )
  }

  return (
    <Box position='relative' width={'full'}>
      <Image resizeMode='cover' source={{uri: banner}} rounded='md' width={'full'} height={[32, 64, 72, 96]} />

      <IconButton icon={<Icon as={MaterialIcons} name='delete' />} colorScheme='error' onPress={removeBanner} position='absolute' top={2} right={2} size='sm' variant='solid' rounded={'sm'} />
    </Box>
  );
}

export default memo(EditItemBanner);
