import { addDoc, collection, CollectionReference, doc, DocumentReference, GeoPoint, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where, WithFieldValue } from 'firebase/firestore';
import { isEmpty, isNil } from 'lodash';
import { Button, FormControl, VStack, HStack, Icon, useTheme, useToast, Box, Text, useDisclose, Actionsheet, Avatar, FlatList, IconButton, View, ScrollView } from 'native-base';
import React, { FC, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { isValidCity, isValidEmail, isValidInput, isValidNewItem, isValidState } from '../../../helpers/validate.helper';
import { Category } from '../../../models/Category.class';
import City from '../../../models/City.class';
import State from '../../../models/State.class';
import {MaterialIcons} from '@expo/vector-icons'
import Item, { ItemMode } from '../../../models/Item.class';
import * as Location from 'expo-location'
import Constants from 'expo-constants'
import { getCoords, getGeoHash } from '../../../helpers/coords.helper';
import useAuth from '../../../hooks/useAuth';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { DashboardStackParamList } from '../navigators/DashboardNavigator';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useItemPermission from '../../../hooks/useItemPermission';
import * as Analytics from 'expo-firebase-analytics'
import useStorage from '../../../hooks/useStorage';
import * as ImagePicker from 'expo-image-picker';
import { v4 as uuidv4 } from 'uuid'
import { sortByNameASC } from '../../../helpers/sort.helper';
import { SelectedForUploadType } from './NewItemPage';
import EditItemSlide from '../../../components/EditItemSlide';
import EditItemBanner from '../../../components/EditItemBanner';
import moment from 'moment';
import { FirestoreCollections } from '../../../models/FirestoreCollections.enum';
import FormTextField from '../../../components/Form/FormTextField';
import DescriptionTextArea from '../../../components/Form/DescriptionTextArea';
import SelectCategory from '../../../components/Form/SelectCategory';
import SelectState from '../../../components/Form/SelectState';
import SelectCity from '../../../components/Form/SelectCity';

export interface EditableItem {
  id: string
  name: string
  nameLowerCase: string
  description: string

  logo: string
  banner: string
  slides: string[]
 
  phone: string
  sms: string
  whatsapp: string
  email: string
  website: string

  facebook: string
  instagram: string
  youtube: string

  address1: string
  address2: string
  zipCode: string
  coords?: GeoPoint | null
  geoHash?: string | null

  active: boolean
  waitingForApproval: boolean
}

const EditItemPage: FC = () => {
  const firestore = getFirestore()
  const theme = useTheme()
  const toast = useToast()
  const route = useRoute<RouteProp<DashboardStackParamList, 'edititem'>>()
  const navigation = useNavigation<NativeStackNavigationProp<DashboardStackParamList>>()

  const { userData } = useAuth();
  const { uploadBlob } = useStorage();

  const {itemId, itemMode} = useMemo<{itemId: string | undefined | null, itemMode: ItemMode}>(() => {
    if (route && route.params && route.params.id) {
      return {itemId: route.params.id, itemMode: route.params.mode ?? ItemMode.ITEM}
    }

    return {itemId: undefined, itemMode: ItemMode.ITEM}
  }, [route])

  const {permission} = useItemPermission(itemId, userData, itemMode)

  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  
  const [categories, setCategories] = useState<Category[]>()
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<Category>()

  const [states, setStates] = useState<State[]>()
  const [selectedStateId, setSelectedStateId] = useState<string>('')
  const [selectedState, setSelectedState] = useState<State>()
  
  const [cities, setCities] = useState<City[]>()
  const [selectedCityId, setSelectedCityId] = useState<string>('')
  const [selectedCity, setSelectedCity] = useState<City>()

  const [item, setItem] = useReducer((state: EditableItem, newState: Partial<EditableItem>) => {
    return {
      ...state,
      ...newState
    }
  }, {
    id: '',
    name: '',
    nameLowerCase: '',
    description: '',
    logo: '',
    banner: '',
    slides: [],
    phone: '',
    sms: '',
    whatsapp: '',
    email: '',
    website: '',
    facebook: '',
    instagram: '',
    youtube: '',
    address1: '',
    address2: '',
    zipCode: '',
    coords: null,
    geoHash: null,
    active: false,
    waitingForApproval: false
  })
  const [newCityName, setNewCityName] = useState<string>('')

  const [selectedForUpload, setSelectedForUpload] = useState<SelectedForUploadType>('logo')
  const [isUploading, setUploading] = useState<boolean>(false)

  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclose();

  const goBack = useCallback(() => {
    if (navigation) {
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        navigation.push('home')
      }
    }
  }, [navigation])

  const getCityOrCreateNew = useCallback(async (): Promise<City | undefined> => {
    if (selectedCity) {
      return selectedCity
    }

    if (!selectedState || !newCityName || newCityName.trim().length === 0) {
      return undefined
    }

    try {
      const newCityRef = await addDoc(collection(firestore, `${selectedState.snapshot.ref.path}/cities`) as CollectionReference<City>, {
        name: newCityName,
        state: {
          name: selectedState.name,
          acronym: selectedState.acronym,
          ref: selectedState.snapshot.ref
        }
      } as unknown as WithFieldValue<City>)

      if (newCityRef) {
        const doc = await getDoc(newCityRef)

        if (doc.exists()) {
          return new City(doc)
        }
      }
    } catch (e) {
      console.log('NEW CITY - ERROR')
      console.error(e)
    }

    return undefined
  }, [selectedState, selectedCity, newCityName])

  const uploadImageAsync = useCallback(async (uri: string) => {
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    let folder = 'logos/'
    switch(selectedForUpload) {
      case 'slides':
        folder = 'slides/'
        break

      case 'banner':
        folder = 'banners/'
        break

      case 'logo':
      default:
        folder = 'logos/'
        break;
    }

    return uploadBlob(uuidv4(), blob, folder)
  }, [uploadBlob, selectedForUpload])

  const handleImagePicked = useCallback(async (pickerResult: ImagePicker.ImagePickerResult) => {
    if (!isEmpty(item.id)) {
      try {
        setUploading(true)

        if (!pickerResult.canceled && pickerResult.assets.length > 0) {
          const uploadUrl = await uploadImageAsync(pickerResult.assets[0].uri);
          
          switch(selectedForUpload) {
            case 'logo':
              setItem({
                logo: uploadUrl
              })
              break;
  
            case 'slides':
              const newSlides = Array.from(item.slides)
              newSlides.push(uploadUrl)
  
              setItem({
                slides: newSlides
              })
              break
  
            case 'banner':
              setItem({
                banner: uploadUrl
              })
              break;
  
            default:
              break
          }
        }
      } catch (e) {
        // ...
      } finally {
        setUploading(false)
      }
    }
  }, [uploadImageAsync, item, selectedForUpload])

  const takePhoto = useCallback(async () => {
    onClose();

    let pickerResult = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      aspect: [1, 1],
    });

    return handleImagePicked(pickerResult);
  }, [onClose, handleImagePicked]);

  const pickImage = useCallback(async () => {
    onClose();

    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [1, 1],
    });

    return handleImagePicked(pickerResult);
  }, [onClose, handleImagePicked]);

  const handleOpenImageActionSheet = useCallback((selected: SelectedForUploadType) => {
    setSelectedForUpload(selected)
    onOpen()
  }, [onOpen])

  const handleRemoveLogo = useCallback(() => {
    setItem({
      logo: ''
    })
  }, []) 

  const handleRemoveBanner = useCallback(() => {
    setItem({
      banner: ''
    })
  }, [])

  const handleRemoveSlide = useCallback((index: number) => {
    if (!isEmpty(item.id) || item.slides.length - 1 < index) {
      return
    }

    const newSlides = Array.from(item.slides)
    newSlides.splice(index, 1)

    setItem({
      slides: newSlides
    })
  }, [item])

  const getPersonalizedErrorMessage = useCallback((newItem: EditableItem, selectedCityId: string, category?: Category, state?: State, city?: City, newCityName?: string) => {
    // console.log('GET PERSONALIZED ERROR MESSAGE')

    if (!isValidInput(newItem.name, 1)) {
      return 'Por favor, informe o nome do item.'
    }

    if (!isNil(newItem.email) && newItem.email.trim().length > 0 && !isValidEmail(newItem.email)) {
      return 'Por favor, informe um e-mail válido.'
    }

    if (isNil(category)) {
      return 'Por favor, selecione uma categoria.'
    }

    if (!isValidState(newItem.address1, state)) {
      return 'Por favor, selecione um estado.'
    }

    if (!isValidCity(selectedCityId, newItem.address1, city, newCityName)) {
      return 'Por favor, selecione uma cidade.'
    }
    
    return 'Por favor, corrija os campos inválidos.'
  }, [])

  const handleSubmitButtonClick = useCallback(async () => {
    if (isEmpty(item.id) || isNil(permission)) {
      console.log('NO PERMISSION OR ITEM ID', permission, item.id)
      return
    }

    setLoading(true)
    setSubmitted(true)

    if (!isValidNewItem(item, selectedCityId, selectedCategory, selectedState, selectedCity, newCityName)) {
      // log all arguments sent to isValidNewItem
      console.log('INVALID NEW ITEM', item, selectedCityId, selectedCategory, selectedState, selectedCity, newCityName)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4}>
            <Text color='white'>{getPersonalizedErrorMessage(item, selectedCityId, selectedCategory, selectedState, selectedCity, newCityName)}</Text>
          </Box>
        )
      })

      setLoading(false)
      return;
    }

    const city = await getCityOrCreateNew()

    try {
      const coords: GeoPoint | null = itemMode === 'new' ? null : await getCoords(selectedState?.name ?? '', item.address1 ?? '', item.address2 ?? '', item.zipCode ?? '', city?.name ?? '')
      const geoHash = getGeoHash(coords)

      let itemRef: DocumentReference<Item>;

      const changeParams = {
        name: item.name,
        nameLowerCase: item.name.replaceAll("'", '').replaceAll('"', '').toLowerCase(),
        description: item.description.trim(),
        active: false,
        waitingForApproval: true,

        logo: (item.logo ?? '').trim().length > 0 ? (item.logo ?? '').trim() : null,
        banner: (item.banner ?? '').trim().length > 0 ? (item.banner ?? '').trim() : null,
        slides: item.slides,
        phone: (item.phone ?? '').trim().length > 0 ? (item.phone ?? '').trim() : null,
        sms: (item.sms ?? '').trim().length > 0 ? (item.sms ?? '').trim() : null,
        whatsapp: (item.whatsapp ?? '').trim().length > 0 ? (item.whatsapp ?? '').trim() : null,
        email: (item.email ?? '').trim().length > 0 ? (item.email ?? '').trim() : null,
        website: (item.website ?? '').trim().length > 0 ? (item.website ?? '').trim() : null,

        facebook: (item.facebook ?? '').trim().length > 0 ? (item.facebook ?? '').trim() : null,
        instagram: (item.instagram ?? '').trim().length > 0 ? (item.instagram ?? '').trim() : null,
        youtube: (item.youtube ?? '').trim().length > 0 ? (item.youtube ?? '').trim() : null,

        address1: (item.address1 ?? '').trim().length > 0 ? (item.address1 ?? '').trim() : null,
        address2: (item.address2 ?? '').trim().length > 0 ? (item.address2 ?? '').trim() : null,
        zipCode: (item.zipCode ?? '').trim().length > 0 ? (item.zipCode ?? '').trim() : null,
        coords,
        geoHash,

        updatedAt: moment().utc().toDate(),
        
        state: selectedState ? {
          name: selectedState.name,
          acronym: selectedState.acronym,
          ref: selectedState.snapshot.ref
        } : null,
        city: city ? {
          name: city.name,
          ref: city.snapshot.ref,
        } : null,
        category: {
          name: selectedCategory!.name,
          ref: selectedCategory!.snapshot.ref,
        },
      }

      if (['change', 'new'].includes(itemMode)) {
        itemRef = permission.itemRef
        await updateDoc(itemRef, changeParams)
      } else {
        itemRef = doc(firestore, FirestoreCollections.REQUESTED_CHANGES, permission.itemRef.id) as DocumentReference<Item>
        await setDoc(itemRef, changeParams as WithFieldValue<Item>)
      }

      const itemDoc = await getDoc(itemRef)

      if (!itemDoc.exists()) {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color='white'>Ops! Ocorreu um erro ao tentar obter o novo Item. Por favor, verifique se o mesmo foi salvo.</Text>
            </Box>
          )
        })
  
        setLoading(false)
        return
      } else {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color={'white'}>Item salvo com sucesso! As alterações entrarão para análise do nosso time e publicadas no BrasilAmericano assim que aprovadas.</Text>
            </Box>
          )
        })
  
        setLoading(false)
        navigation.navigate('home')
        return
      }
    } catch (e) {
      console.log('NEW ITEM - ERROR')
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color='white'>Ops! Ocorreu um erro ao tentar salvar o Item. Por favor, tente novamente.</Text>
          </Box>
        )
      })

      setLoading(false)
      return
    }
  }, [item, permission, selectedCategory, selectedState, selectedCity, selectedCityId, newCityName, firestore, getPersonalizedErrorMessage, navigation, itemMode, toast])

  const clearItem = useCallback(() => {
    setItem({
      id: '',
      name: '',
      nameLowerCase: '',
      description: '',
      logo: '',
      banner: '',
      slides: [],
      phone: '',
      sms: '',
      whatsapp: '',
      email: '',
      website: '',
      facebook: '',
      instagram: '',
      youtube: '',
      address1: '',
      address2: '',
      zipCode: '',
      coords: null,
      geoHash: null,
      active: false,
      waitingForApproval: false
    })
  }, [])

  useEffect(() => {
    if (permission && (isEmpty(item.id) || item.id !== permission.itemRef.id)) {
      setLoading(true)
      clearItem()

      getDoc(permission.itemRef).then(result => {
        if (!result.exists()) {
          setLoading(false)
          return
        }

        const newItem = new Item(result)

        setItem({
          id: permission.itemRef.id,
          name: newItem.name,
          nameLowerCase: newItem.name.replaceAll("'", '').replaceAll('"', '').toLowerCase(),
          description: newItem.description,
          logo: newItem.logo ?? '',
          banner: newItem.banner ?? '',
          slides: newItem.slides,

          phone: newItem.phone ?? '',
          sms: newItem.sms ?? '',
          whatsapp: newItem.whatsapp ?? '',
          email: newItem.email ?? '',
          website: newItem.website ?? '',

          facebook: newItem.facebook ?? '',
          instagram: newItem.instagram ?? '',
          youtube: newItem.youtube ?? '',

          address1: newItem.address1 ?? '',
          address2: newItem.address2 ?? '',
          zipCode: newItem.zipCode ?? '',

          active: false,
          waitingForApproval: true
        })
        setSelectedCategoryId(newItem.category.ref.id)
        setSelectedStateId(newItem.state?.ref.id ?? '')
        setSelectedCityId(newItem.city?.ref.id ?? '')
        setLoading(false)
      }).catch(e => {
        console.error(e)
        
        try {
          Analytics.logEvent('edit_item_error', {
            method: 'fetch_permission',
            itemId: permission.itemRef.id,
            level: permission.level,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }

        setLoading(false)
      })
    }
  }, [permission, clearItem])

  useEffect(() => {
    if ((!selectedStateId || selectedStateId.length === 0) && selectedState) {
      setSelectedState(undefined)
      return
    }

    if (states && selectedStateId && selectedStateId.length > 0 && (!selectedState || selectedState.id !== selectedStateId)) {
      setSelectedState(states.find(state => state.id === selectedStateId))
    }
  }, [selectedStateId, selectedState, states])

  useEffect(() => {
    if ((!selectedCityId || selectedCityId.length === 0) && selectedCity) {
      setSelectedCity(undefined)
      return
    }

    if (cities && selectedCityId && selectedCityId.length > 0 && (!selectedCity || selectedCity.id !== selectedCityId)) {
      setSelectedCity(cities.find(city => city.id === selectedCityId))
    }
  }, [selectedCityId, selectedCity, cities])

  useEffect(() => {
    if (!selectedState) {
      setCities([])
      setSelectedCityId('');
    } else {
      ;(async () => {
        const newCities = await selectedState.fetchCities()
        
        if (!newCities.find(city => city.id === selectedCityId)) {
          setSelectedCityId('')
        }

        setCities(newCities)
      })()
    }
  }, [selectedState])

  useEffect(() => {
    if ((!selectedCategoryId || selectedCategoryId.length === 0) && selectedCategory) {
      setSelectedCategory(undefined)
      return
    }

    if (categories && selectedCategoryId && selectedCategoryId.length > 0 && (!selectedCategory || selectedCategory.id !== selectedCategoryId)) {
      setSelectedCategory(categories.find(category => category.id === selectedCategoryId))
    }
  }, [selectedCategoryId, selectedCategory, categories])

  useEffect(() => {
    if (isNil(states)) {
      ;(async () => {
        try {
          const result = await getDocs(query(collection(firestore, 'states') as CollectionReference<State>))
          
          const newStates: State[] = []
          result.forEach(doc => newStates.push(new State(doc)))

          newStates.sort(sortByNameASC)

          setStates(newStates)
        } catch (e) {
          console.log('STATES - ERROR')
          console.error(e)
        }
      })()
    }
  }, [states, firestore])

  useEffect(() => {
    if (isNil(categories)) {
      ;(async () => {
        try {
          const result = await getDocs(query(collection(firestore, 'categories') as CollectionReference<Category>, where('active', '==', true)))
          
          const newCategories: Category[] = []
          result.forEach(doc => newCategories.push(new Category(doc)))

          setCategories(newCategories)
        } catch (e) {
          console.log('CATEGORIES - ERROR')
          console.error(e)
        }
      })()
    }
  }, [categories, firestore])

  useEffect(() => {
    try {
      Location.setGoogleApiKey(Constants.manifest?.extra?.googleApiKey)
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('edit_item_error', {
          method: 'setGoogleApiKey',
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  if (!item) {
    return (
      <View flex={1} width='full' overflow='hidden'>
        <ScrollView flex={1} padding={4}>
          <VStack width={'full'} p={4}>
            <Text></Text>
          </VStack>
        </ScrollView>
      </View>
    )
  }

  return (
    <View flex={1} width='full' overflow='hidden'>
      <ScrollView flex={1}>
        <VStack space={4} alignItems='center' bgColor={theme.colors.white} p={4} rounded='md'>
          <HStack space={2} width='full' justifyContent={'center'} alignItems='center'>
            <Box>
              {item.logo && item.logo.length > 0 ? (
                <Box position='relative'>
                  <Avatar
                    source={{ uri: item.logo }}
                    bgColor={theme.colors.white}
                    borderWidth={1}
                    borderColor={theme.colors.primary[800]}
                    size={'md'}
                    alignSelf={'center'}
                  >
                    <Text p={2} color={theme.colors.primary[800]} numberOfLines={1} ellipsizeMode='tail' isTruncated={true} fontSize={'xs'}>{item.name ?? ''}</Text>
                  </Avatar>

                  <IconButton 
                    icon={<Icon as={MaterialIcons} name='delete' size={4} />} 
                    colorScheme='error' 
                    onPress={() => handleRemoveLogo()} 
                    position='absolute' 
                    top={0} 
                    right={0} 
                    width={4}
                    height={4}
                    rounded={'xs'} 
                    padding={0}
                    variant='solid'
                  />
                </Box>
              ) : (
                <IconButton 
                  icon={
                    <Icon as={MaterialIcons} name='camera-alt' />
                  } 
                  colorScheme='primary' 
                  size='md'
                  rounded={'full'}
                  borderWidth={1}
                  borderColor={theme.colors.primary[800]}
                  onPress={() => handleOpenImageActionSheet('logo')}
                  isDisabled={isUploading}
                  variant='solid'
                />
              )}

              <Actionsheet isOpen={isOpen} onClose={onClose}>
                <Actionsheet.Content>
                  <Actionsheet.Item onPress={pickImage}>Escolher da Biblioteca</Actionsheet.Item>
                  <Actionsheet.Item onPress={takePhoto}>Abrir Camera</Actionsheet.Item>
                  <Actionsheet.Item onPress={onClose}>Cancelar</Actionsheet.Item>
                </Actionsheet.Content>
              </Actionsheet>
            </Box>

            <FormTextField
              isRequired={true}
              isInvalid={submitted && !isValidInput(item.name, 1)}
              setAttribute={(attr, value) => setItem({[attr]: value})}
              errorMessage='Por favor, providencie um título válido.'
              type='text'
              placeholder='Título - Ex.: Brasil Americano'
              attributeName='name'
              autoComplete='off'
              value={item.name}
            />
          </HStack>

          <EditItemBanner banner={item.banner} addBanner={handleOpenImageActionSheet} removeBanner={handleRemoveBanner} isLoading={isUploading} />

          <FormControl>
            <VStack space={2}>
              <FlatList
                data={['', '', '']}
                keyExtractor={() => uuidv4()}
                horizontal={true}
                renderItem={({index}) => <EditItemSlide index={index} slides={item.slides} addSlide={handleOpenImageActionSheet} removeSlide={handleRemoveSlide} isLoading={isUploading} />}
              />

              <FormControl.HelperText>Até, no máximo, 3 slides.</FormControl.HelperText>
            </VStack>
          </FormControl>

          <DescriptionTextArea 
            setAttribute={(attr, value) => setItem({[attr]: value})}
            value={item.description}
          />

          <HStack flexWrap={'wrap'} justifyContent='center' alignItems={'center'} space={2} marginBottom={2} w="full">
            <SelectCategory 
              isInvalid={submitted && !selectedCategory} 
              selectedValue={selectedCategoryId} 
              setSelectedCategoryId={setSelectedCategoryId} 
              categories={categories} 
            />
          </HStack>

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='+14074700000'
            attributeName='phone'
            autoComplete='tel'
            keyboardType='phone-pad'
            value={item.phone}
            label='Telefone/Celular'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='+14074700000'
            attributeName='sms'
            autoComplete='tel'
            keyboardType='phone-pad'
            value={item.sms}
            label='SMS'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='+14074700000'
            attributeName='whatsapp'
            autoComplete='tel'
            keyboardType='phone-pad'
            value={item.whatsapp}
            label='WhatsApp'
          />

          <FormTextField
            isInvalid={submitted && !isNil(item.email) && item.email.length > 0 && !isValidEmail(item.email)}
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='exemplo@exemplo.com'
            attributeName='email'
            autoComplete='email'
            keyboardType='email-address'
            value={item.email}
            label='Email'
            errorMessage='Por favor, insira um email válido.'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='https://exemplo.com/'
            attributeName='website'
            autoComplete='off'
            keyboardType='url'
            value={item.website}
            label='Website'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='Somente o nickname do perfil no Instagram'
            attributeName='instagram'
            autoComplete='off'
            keyboardType='email-address'
            value={item.instagram}
            label='Instagram'
            helperMessage='Somente o nickname do perfil no Instagram, sem o @.'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='Somente o nickname do perfil no Facebook'
            attributeName='facebook'
            autoComplete='off'
            keyboardType='email-address'
            value={item.facebook}
            label='Facebook'
            helperMessage='Somente o nickname do perfil no Facebook, sem o @.'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='c/NomeDoCanal'
            attributeName='youtube'
            autoComplete='off'
            keyboardType='url'
            value={item.youtube}
            label='YouTube'
            helperMessage='Somente a parte final da URL do canal. Ex.: c/BLOGamerosBR ou channel/UCwaOlgGFAJHD'
          />

          <HStack flexWrap={'wrap'} justifyContent='center' alignItems={'center'} space={2} marginBottom={2} w="full">
            <SelectState 
              isRequired={submitted && !isEmpty(item.address1.trim())}
              isInvalid={!(!submitted || isValidState(item.address1, selectedState))}
              selectedValue={selectedStateId}
              setSelectedStateId={setSelectedStateId}
              states={states}
            />

            <SelectCity
              isRequired={submitted && !isEmpty(item.address1.trim())} 
              isInvalid={!(!submitted || isValidCity(selectedCityId, item.address1, selectedCity, newCityName))}
              selectedValue={selectedCityId}
              setSelectedCityId={setSelectedCityId}
              cities={cities}
            />
          </HStack>

          {selectedCityId === 'other' ? (
            <FormTextField 
              isRequired={true}
              isInvalid={submitted && selectedCityId === 'other' && !isValidInput(newCityName, 1)}
              setAttribute={(_, value) => setNewCityName(value)}
              label='Nome da Cidade'
              type='text'
              attributeName='newCityName'
              autoComplete='postal-address-locality'
              errorMessage='Por favor, providencie um nome válido.'
            />
          ) : null}

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='4515 Avenue Brazil'
            attributeName='address1'
            autoComplete='postal-address'
            value={item.address1}
            label='Endereço 1'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='Unit 123'
            attributeName='address2'
            autoComplete='postal-address-extended'
            value={item.address2}
            label='Endereço 2'
          />

          <FormTextField
            setAttribute={(attr, value) => setItem({[attr]: value})}
            type='text'
            placeholder='33333'
            attributeName='zipCode'
            autoComplete='postal-code'
            value={item.zipCode}
            label='Zip Code'
          />

          <Button isLoading={loading} isLoadingText='Salvando' width={'full'} rounded='full' onPress={handleSubmitButtonClick}>
            Salvar
          </Button>

          <Button width={'full'} rounded='full' variant='outline' onPress={() => goBack()} disabled={loading}>
            Voltar
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(EditItemPage);
