import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../../../components/AppHeader';
import Functions from '../views/Functions';

export type FunctionsStackParamList = {
  dashboard: undefined;
};

const Stack = createNativeStackNavigator<FunctionsStackParamList>()

const FunctionsNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='dashboard' screenOptions={{
      header: (props) => <AppHeader {...props} />
    }}>
      <Stack.Screen name="dashboard" component={Functions} options={{
        title: 'BrasilAmericano | Funções Admin'
      }} />
    </Stack.Navigator>
  )
}

export default memo(FunctionsNavigator)
