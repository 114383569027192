import { collection, CollectionReference, DocumentReference, DocumentSnapshot, getDocs, query } from "firebase/firestore"
import { FirestoreSnapshot } from "./FirestoreSnapshot.class"
import Permission from "./Permission.class"
import * as Analytics from 'expo-firebase-analytics'

export type UserRole = 'admin' | 'mod' | 'user'

export interface UserRef {
  firstName: string
  email: string
  ref: DocumentReference<User>
}

export class User extends FirestoreSnapshot<User> {
  firstName: string
  lastName: string
  email: string
  photo: string
  role: UserRole
  createdAt: Date
  updatedAt?: Date | null

  permissions?: Permission[]

  constructor(doc: DocumentSnapshot<User>) {
    super(doc)
    
    const data = doc.data()!
    
    this.firstName = data.firstName ?? ''
    this.lastName = data.lastName ?? ''
    this.email = data.email
    this.photo = data.photo ?? ''
    this.role = data.role
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
  }

  async fetchPermissions(): Promise<Permission[]> {
    this.permissions = []
    
    try {
      const permissionsCollection = collection(this.snapshot.ref.firestore, this.snapshot.ref.path, 'permissions') as CollectionReference<Permission>;

      const result = await getDocs(query(permissionsCollection))
      
      result.forEach(doc => this.permissions?.push(new Permission(doc)))
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('user_error', {
          method: 'fetchPermissions',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.permissions
  }
}
