export enum FirestoreCollections {
  ITEMS_FOR_APPROVAL = 'itemsForApproval',
  REQUESTED_CHANGES = 'requestedChanges',
  ITEMS = 'items',
  USERS = 'users',
  STATES = 'states',
  CITIES = 'cities',
  PERMISSIONS = 'permissions',
  FAVORITES = 'favorites',
  BUTTON_CLICKS = 'buttonClicks',
  OPEN_DETAILS = 'openDetails',
  PAGE_VIEWS = 'pageViews',
  MAP_VIEWS = 'mapViews',
  SEARCH_VIEWS = 'searchViews',
  ITEM_MEMBERS = 'members',
  CATEGORIES = 'categories'
}
