import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth';

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoreActions: true,
      ignoreState: true
    },
  })
});

export default store;
