import React, { FC, memo, useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { VStack, FormControl, Input, WarningOutlineIcon, Image, Button, View, ScrollView } from 'native-base';
import { isValidEmail, isValidPassword, isValidSignUpCredentials, PASSWORD_MIN_LENGTH } from '../../helpers/validate.helper';
import useAuth from '../../hooks/useAuth';
import { RootStackParamList } from '../NavigationContainerManager';
import { doc, DocumentReference, getFirestore, setDoc } from 'firebase/firestore';
import { User as FirebaseUser } from 'firebase/auth';
import { User } from '../../models/User.class';
import * as Analytics from 'expo-firebase-analytics'
import moment from 'moment';

const SignUp: FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const firestore = getFirestore()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  
  const {signUp} = useAuth()

  const goToSignIn = () => {
    navigation.navigate('signin')
  }

  const createProfile = useCallback(async (user: FirebaseUser) => {
    if (firestore && user) {
      return setDoc(doc(firestore, 'users', user.uid) as DocumentReference<Pick<User, 'firstName' | 'lastName' | 'email' | 'role' | 'createdAt' >>, {
        firstName: '',
        lastName: '',
        email: user.email ?? '',
        role: 'user',
        createdAt: moment().utc().toDate()
      })
    }

    try {
      Analytics.logEvent('users_error', {
        method: 'fetch_users',
        message: 'invalid firestore or user'
      })
    } catch (e) {
      console.error(e)
    }
  }, [firestore])

  const handleSignUpClick = useCallback(async () => {
    setSubmitted(true)
    setLoading(true)

    if (isValidSignUpCredentials({email, password, confirmPassword})) {
      try {
        const user = await signUp(email, password)
        if (!user) {
          throw new Error('invalid user')
        }
        await createProfile(user)
        navigation.push('protected')
      } catch (e) {
        console.error(e)
        setLoading(false)

        try {
          Analytics.logEvent('sign_up_error', {
            method: 'handleSignUpClick',
            email,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
      }
    } else {
      setLoading(false)
    }
  }, [email, password, confirmPassword, signUp, navigation, createProfile])

  return (
    <View flex={1} width='full' overflow='hidden'>
      <ScrollView flex={1} padding={4}>
        <VStack space={4} alignItems='center'>
          <Image source={require('../../assets/logo.png')} width={120} height={120} resizeMode='contain' />

          <FormControl isRequired isInvalid={submitted && !isValidEmail(email)}>
            <VStack>
              <Input type="text" keyboardType='email-address' autoComplete='email' variant={'rounded'} placeholder='Email' value={email} onChangeText={text => setEmail(text)} />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Por favor, insira um email válido.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={submitted && !isValidPassword(password)}>
            <VStack>
              <Input type="password" autoComplete='password-new' variant={'rounded'} placeholder='Senha' value={password} onChangeText={text => setPassword(text)} />
              <FormControl.HelperText>
                {`A senha deve ter pelo menos ${PASSWORD_MIN_LENGTH} caracteres.`}
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                {`Pelo menos ${PASSWORD_MIN_LENGTH} caracteres são obrigatórios.`}
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={password !== confirmPassword}>
            <VStack>
              <Input type="password" autoComplete='password-new' variant={'rounded'} placeholder='Confirmação de Senha' value={confirmPassword} onChangeText={text => setConfirmPassword(text)} />
              <FormControl.HelperText>
                Insira a senha novamente.
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                A senhas devem ser idênticas.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>
          
          <Button isLoading={loading} isLoadingText='Registrando' width={'full'} rounded='full' onPress={handleSignUpClick}>
            Registrar
          </Button>

          <Button width={'full'} rounded='full' variant='link' onPress={goToSignIn} disabled={loading}>
            Já possui uma conta? Entre aqui
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(SignUp);
