import React, { memo } from 'react';
import { useTheme } from 'native-base'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {Ionicons} from '@expo/vector-icons'
import FunctionsNavigator from './navigators/FunctionsNavigator';
import useAuth from '../../hooks/useAuth';
import DashboardNavigator from './navigators/DashboardNavigator';
import UsersNavigator from './navigators/UsersNavigator';

export type ProtectedTabParamsList = {
  dashboard: undefined;
  users: undefined;
  functions: undefined;
};

const Tab = createBottomTabNavigator<ProtectedTabParamsList>()

const ProtectedTabNavigator = () => {
  const theme = useTheme()
  const {userData} = useAuth()

  return (
    <Tab.Navigator screenOptions={({route}) => ({
      headerShown: false,
      tabBarIcon: ({focused, color, size}) => {
        let iconName: any = '';

        switch(route.name) {
          case 'dashboard':
            iconName = focused ? 'md-home' : 'md-home-outline'
            break

          case 'users':
            iconName = focused ? 'md-person' : 'md-person-outline'
            break
          
          case 'functions':
            iconName = focused ? 'construct-sharp' : 'construct-outline'
            break

          default:
            break
        }

        return <Ionicons name={iconName} color={color} size={size} />
      },
      tabBarActiveTintColor: theme.colors.primary[800],
      tabBarInactiveTintColor: theme.colors.primary[400],
    })}>
      <Tab.Screen name="dashboard" component={DashboardNavigator} options={{
        title: 'Dashboard'
      }} />
      
      {userData && userData.role === 'admin' ? (
        <>
          <Tab.Screen name="users" component={UsersNavigator} options={{
            title: 'Usuários'
          }} />
          <Tab.Screen name="functions" component={FunctionsNavigator} options={{
            title: 'Funções'
          }} />
        </>
      ): null}
    </Tab.Navigator>
  );
}

export default memo(ProtectedTabNavigator)
