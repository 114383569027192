import { addDoc, collection, CollectionReference, GeoPoint, getDoc, getDocs, getFirestore, query, where, WithFieldValue } from 'firebase/firestore';
import { isEmpty, isNil } from 'lodash';
import { Button, FormControl, VStack, HStack, Icon, useTheme, useToast, Box, Actionsheet, useDisclose, Avatar, FlatList, Text, IconButton, View, ScrollView } from 'native-base';
import React, { FC, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { isValidCity, isValidEmail, isValidInput, isValidNewItem, isValidState } from '../../../helpers/validate.helper';
import { Category } from '../../../models/Category.class';
import City from '../../../models/City.class';
import State from '../../../models/State.class';
import {MaterialIcons, FontAwesome, FontAwesome5, MaterialCommunityIcons, Entypo, Ionicons} from '@expo/vector-icons'
import Item from '../../../models/Item.class';
import * as Location from 'expo-location'
import Constants from 'expo-constants'
import useAuth from '../../../hooks/useAuth';
import * as Analytics from 'expo-firebase-analytics'
import useStorage from '../../../hooks/useStorage';
import * as ImagePicker from 'expo-image-picker';
import { v4 as uuidv4 } from 'uuid'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { DashboardStackParamList } from '../navigators/DashboardNavigator';
import useItemPermission from '../../../hooks/useItemPermission';
import { sortByNameASC } from '../../../helpers/sort.helper';
import EditItemSlide from '../../../components/EditItemSlide';
import EditItemBanner from '../../../components/EditItemBanner';
import { FirestoreCollections } from '../../../models/FirestoreCollections.enum';
import moment from 'moment';
import DescriptionTextArea from '../../../components/Form/DescriptionTextArea';
import SelectCategory from '../../../components/Form/SelectCategory';
import FormTextField from '../../../components/Form/FormTextField';
import SelectState from '../../../components/Form/SelectState';
import SelectCity from '../../../components/Form/SelectCity';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export type SelectedForUploadType = 'logo' | 'slides' | 'banner'

export interface NewItem {
  cloneId?: string | null
  name: string
  nameLowerCase: string
  description: string

  logo: string
  banner: string
  slides: string[]
  
  phone: string
  sms: string
  whatsapp: string
  email: string
  website: string

  facebook: string
  instagram: string
  youtube: string

  address1: string
  address2: string
  zipCode: string
  coords?: GeoPoint | null
  geoHash?: string | null

  active: boolean
  waitingForApproval: boolean
}

export interface NewItemValidation {
  name: boolean
  category: boolean
  email: boolean
  state: boolean
  city: boolean
  cityName: boolean
}

const brandNewItem: NewItem = {
  name: '',
  nameLowerCase: '',
  description: '',
  logo: '',
  banner: '',
  slides: [],

  phone: '',
  sms: '',
  whatsapp: '',
  email: '',
  website: '',

  facebook: '',
  instagram: '',
  youtube: '',

  address1: '',
  address2: '',
  zipCode: '',

  active: false,
  waitingForApproval: true
}

const NewItemPage: FC = () => {
  const firestore = getFirestore()
  const theme = useTheme()
  const toast = useToast()
  const route = useRoute<RouteProp<DashboardStackParamList, 'newitem'>>()
  const navigation = useNavigation<NativeStackNavigationProp<DashboardStackParamList>>()

  const { userData } = useAuth();
  const { uploadBlob } = useStorage();

  const cloneId = useMemo<string | undefined | null>(() => {
    if (route && route.params && route.params.id) {
      return route.params.id
    }

    return undefined
  }, [route])

  const {permission} = useItemPermission(cloneId, userData)

  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)
  
  const [categories, setCategories] = useState<Category[]>()
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<Category>()

  const [states, setStates] = useState<State[]>()
  const [selectedStateId, setSelectedStateId] = useState<string>('')
  const [selectedState, setSelectedState] = useState<State>()
  
  const [cities, setCities] = useState<City[]>()
  const [selectedCityId, setSelectedCityId] = useState<string>('')
  const [selectedCity, setSelectedCity] = useState<City>()

  const [cloneItem, setCloneItem] = useState<Item>()
  const [newItem, setNewItem] = useReducer((state: NewItem, newState: Partial<NewItem>) => {
    return {
      ...state,
      ...newState
    }
  }, brandNewItem)
  const [newCityName, setNewCityName] = useState<string>('')

  const [selectedForUpload, setSelectedForUpload] = useState<SelectedForUploadType>('logo')
  const [isUploading, setUploading] = useState<boolean>(false)

  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclose();

  const resetItemByClone = useCallback((baseItem: Item) => {
    // console.log('resetItemByClone', baseItem)
    setNewItem({
      cloneId: baseItem.id,
      name: baseItem.name,
      nameLowerCase: baseItem.name.replaceAll("'", '').replaceAll('"', '').toLowerCase(),
      description: baseItem.description,
      logo: baseItem.logo ?? '',
      banner: baseItem.banner ?? '',
      slides: baseItem.slides,

      phone: baseItem.phone ?? '',
      sms: baseItem.sms ?? '',
      whatsapp: baseItem.whatsapp ?? '',
      email: baseItem.email ?? '',
      website: baseItem.website ?? '',

      facebook: baseItem.facebook ?? '',
      instagram: baseItem.instagram ?? '',
      youtube: baseItem.youtube ?? '',

      address1: baseItem.address1 ?? '',
      address2: baseItem.address2 ?? '',
      zipCode: baseItem.zipCode ?? '',

      active: false,
      waitingForApproval: true
    })
    setSelectedCategoryId(baseItem.category.ref.id)
    setSelectedStateId(baseItem.state?.ref.id ?? '')
    setSelectedCityId(baseItem.city?.ref.id ?? '')
  }, [])

  const reset = useCallback((hideToast: boolean = false) => {
    // console.log('reset', hideToast)
    setSubmitted(false)

    if (cloneItem) {
      resetItemByClone(cloneItem)
    } else {
      setNewItem(brandNewItem)
      setSelectedCategoryId('')
      setSelectedStateId('')
      setNewCityName('')
    }

    if (!hideToast) {
      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.info[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Formulário limpo!</Text>
          </Box>
        )
      })
    }
  }, [cloneItem, resetItemByClone])

  const uploadImageAsync = useCallback(async (uri: string) => {
    // console.log('uploadImageAsync', uri)
    const blob: Blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    let folder = 'logos/'
    switch(selectedForUpload) {
      case 'slides':
        folder = 'slides/'
        break

      case 'banner':
        folder = 'banners/'
        break

      case 'logo':
      default:
        folder = 'logos/'
        break;
    }

    return uploadBlob(uuidv4(), blob, folder)
  }, [uploadBlob, selectedForUpload])

  const handleImagePicked = useCallback(async (pickerResult: ImagePicker.ImagePickerResult) => {
    // console.log('handleImagePicked', pickerResult)
    try {
      setUploading(true)

      if (!pickerResult.canceled) {
        const uploadUrl = await uploadImageAsync(pickerResult.assets[0].uri);

        switch(selectedForUpload) {
          case 'logo':
            setNewItem({
              ...newItem,
              logo: uploadUrl
            })
            break;

          case 'slides':
            const newSlides = Array.from(newItem.slides)
            newSlides.push(uploadUrl)

            setNewItem({
              ...newItem,
              slides: newSlides
            })
            break

          case 'banner':
            setNewItem({
              ...newItem,
              banner: uploadUrl
            })
            break;

          default:
            break
        }
      }
    } catch (e) {
      // ...
    } finally {
      setUploading(false)
    }
  }, [uploadImageAsync, selectedForUpload, newItem])

  const takePhoto = useCallback(async () => {
    // console.log('TAKE PHOTO')
    onClose();

    let pickerResult = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      aspect: [1, 1],
    });

    return handleImagePicked(pickerResult);
  }, [onClose, handleImagePicked]);

  const pickImage = useCallback(async () => {
    // console.log('PICK IMAGE')
    onClose();

    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [1, 1],
    });

    return handleImagePicked(pickerResult);
  }, [onClose, handleImagePicked]);

  const handleOpenImageActionSheet = useCallback((selected: SelectedForUploadType) => {
    // console.log('OPEN IMAGE ACTION SHEET')
    setSelectedForUpload(selected)
    onOpen()
  }, [onOpen])

  const handleRemoveLogo = useCallback(() => {
    // console.log('REMOVE LOGO')
    setNewItem({
      ...newItem,
      logo: ''
    })
  }, [newItem]) 

  const handleRemoveBanner = useCallback(() => {
    // console.log('REMOVE BANNER')
    setNewItem({
      ...newItem,
      banner: ''
    })
  }, [newItem])

  const handleRemoveSlide = useCallback((index: number) => {
    // console.log('REMOVE SLIDE', index)
    if (newItem.slides.length - 1 < index) {
      return
    }

    const newSlides = Array.from(newItem.slides)
    newSlides.splice(index, 1)

    setNewItem({
      ...newItem,
      slides: newSlides
    })
  }, [newItem, setNewItem])

  const getCityOrCreateNew = useCallback(async (): Promise<City | undefined> => {
    // console.log('GET CITY OR CREATE NEW')
    if (selectedCity) {
      return selectedCity
    }

    if (!selectedState || !newCityName || newCityName.trim().length === 0) {
      return undefined
    }

    try {
      const newCityRef = await addDoc(collection(firestore, `${selectedState.snapshot.ref.path}/cities`) as CollectionReference<City>, {
        name: newCityName,
        state: {
          name: selectedState.name,
          acronym: selectedState.acronym,
          ref: selectedState.snapshot.ref
        }
      } as unknown as WithFieldValue<City>)

      if (newCityRef) {
        const doc = await getDoc(newCityRef)

        if (doc.exists()) {
          return new City(doc)
        }
      }
    } catch (e) {
      // console.log('NEW CITY - ERROR')
      console.error(e)
    }

    return undefined
  }, [selectedState, selectedCity, newCityName])

  const getPersonalizedErrorMessage = useCallback((newItem: NewItem, selectedCityId: string, category?: Category, state?: State, city?: City, newCityName?: string) => {
    // console.log('GET PERSONALIZED ERROR MESSAGE')

    if (!isValidInput(newItem.name, 1)) {
      return 'Por favor, informe o nome do item.'
    }

    if (!isNil(newItem.email) && newItem.email.trim().length > 0 && !isValidEmail(newItem.email)) {
      return 'Por favor, informe um e-mail válido.'
    }

    if (isNil(category)) {
      return 'Por favor, selecione uma categoria.'
    }

    if (!isValidState(newItem.address1, state)) {
      return 'Por favor, selecione um estado.'
    }

    if (!isValidCity(selectedCityId, newItem.address1, city, newCityName)) {
      return 'Por favor, selecione uma cidade.'
    }
    
    return 'Por favor, corrija os campos inválidos.'
  }, [])

  const handleSubmitButtonClick = useCallback(async () => {
    // console.log('SUBMIT BUTTON CLICKED')
    if (isNil(userData)) {
      return 
    }

    setLoading(true)
    setSubmitted(true)

    if (!isValidNewItem(newItem, selectedCityId, selectedCategory, selectedState, selectedCity, newCityName)) {
      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4}>
            <Text color='white'>{getPersonalizedErrorMessage(newItem, selectedCityId, selectedCategory, selectedState, selectedCity, newCityName)}</Text>
          </Box>
        )
      })

      setLoading(false)
      return;
    }

    const city = await getCityOrCreateNew()

    try {
      const newItemRef = await addDoc(collection(firestore, FirestoreCollections.ITEMS_FOR_APPROVAL) as CollectionReference<Item>, {
        name: newItem.name,
        nameLowerCase: newItem.name.replaceAll("'", '').replaceAll('"', '').toLowerCase(),
        description: newItem.description.trim(),
        active: newItem.active,
        waitingForApproval: newItem.waitingForApproval,
        relevance: 0,
        membersQuantity: 0,
        authorId: userData.id,

        logo: newItem.logo.trim().length > 0 ? newItem.logo.trim() : null,
        banner: newItem.banner.trim().length > 0 ? newItem.banner.trim() : null,
        slides: newItem.slides,
        phone: newItem.phone.trim().length > 0 ? newItem.phone.trim() : null,
        sms: newItem.sms.trim().length > 0 ? newItem.sms.trim() : null,
        whatsapp: newItem.whatsapp.trim().length > 0 ? newItem.whatsapp.trim() : null,
        email: newItem.email.trim().length > 0 ? newItem.email.trim() : null,
        website: newItem.website.trim().length > 0 ? newItem.website.trim() : null,

        facebook: newItem.facebook.trim().length > 0 ? newItem.facebook.trim() : null,
        instagram: newItem.instagram.trim().length > 0 ? newItem.instagram.trim() : null,
        youtube: newItem.youtube.trim().length > 0 ? newItem.youtube.trim() : null,

        address1: newItem.address1.trim().length > 0 ? newItem.address1.trim() : null,
        address2: newItem.address2.trim().length > 0 ? newItem.address2.trim() : null,
        zipCode: newItem.zipCode.trim().length > 0 ? newItem.zipCode.trim() : null,

        createdAt: moment().utc().toDate(),
        
        state: selectedState ? {
          name: selectedState.name,
          acronym: selectedState.acronym,
          ref: selectedState.snapshot.ref
        } : null,
        city: city ? {
          name: city.name,
          ref: city.snapshot.ref,
        } : null,
        category: {
          name: selectedCategory!.name,
          ref: selectedCategory!.snapshot.ref,
        },
        subCategories: [],
      } as unknown as WithFieldValue<Item>)

      const newItemDoc = await getDoc(newItemRef)

      if (!newItemDoc.exists()) {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color='white'>Ops! Ocorreu um erro ao tentar obter o novo Item. Por favor, verifique se o mesmo foi salvo.</Text>
            </Box>
          )
        })
  
        setLoading(false)
        return
      } else {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color='white'>Parabéns! Seu novo item foi salvo com sucesso! Assim que aprovado o mesmo será listado publicamente no BrasilAmericano.</Text>
            </Box>
          )
        })

        setLoading(false)
        reset(true)
        navigation.navigate('home')
        return
      }
    } catch (e) {
      // console.log('NEW ITEM - ERROR')
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Ops! Ocorreu um erro ao tentar salvar o Item. Por favor, tente novamente.</Text>
          </Box>
        )
      })

      setLoading(false)
      return
    }
  }, [newItem, selectedCategory, selectedState, selectedCity, selectedCityId, newCityName, firestore, reset, userData, getPersonalizedErrorMessage, navigation, toast])

  useEffect(() => {
    // console.log('STATES - EFFECT')
    if ((!selectedStateId || selectedStateId.length === 0) && selectedState) {
      // console.log('STATES - RESET')
      setSelectedState(undefined)
      return
    }

    if (states && selectedStateId && selectedStateId.length > 0 && (!selectedState || selectedState.id !== selectedStateId)) {
      // console.log('STATES - SET')
      setSelectedState(states.find(state => state.id === selectedStateId))
    }
  }, [selectedStateId, selectedState, states])

  useEffect(() => {
    // console.log('CITIES - EFFECT')
    if ((!selectedCityId || selectedCityId.length === 0) && selectedCity) {
      // console.log('CITIES - RESET')
      setSelectedCity(undefined)
      return
    }

    if (cities && selectedCityId && selectedCityId.length > 0 && (!selectedCity || selectedCity.id !== selectedCityId)) {
      // console.log('CITIES - SET')
      setSelectedCity(cities.find(city => city.id === selectedCityId))
    }
  }, [selectedCityId, selectedCity, cities])

  useEffect(() => {
    // console.log('STATES - EFFECT')
    if (!selectedState) {
      // console.log('STATES - RESET')
      setCities([])
      setSelectedCityId('');
    } else {
      // console.log('STATES - FETCH CITIES')
      ;(async () => {
        const newCities = await selectedState.fetchCities()
        
        if (!newCities.find(city => city.id === selectedCityId)) {
          setSelectedCityId('')
        }

        setCities(newCities)
      })()
    }
  }, [selectedState])

  useEffect(() => {
    // console.log('CATEGORIES - EFFECT')
    if ((!selectedCategoryId || selectedCategoryId.length === 0) && selectedCategory) {
      // console.log('CATEGORIES - RESET')
      setSelectedCategory(undefined)
      return
    }

    if (categories && selectedCategoryId && selectedCategoryId.length > 0 && (!selectedCategory || selectedCategory.id !== selectedCategoryId)) {
      // console.log('CATEGORIES - SET')
      setSelectedCategory(categories.find(category => category.id === selectedCategoryId))
    }
  }, [selectedCategoryId, selectedCategory, categories])

  useEffect(() => {
    if (isNil(states)) {
      // console.log('STATES - FETCH')
      ;(async () => {
        try {
          const result = await getDocs(query(collection(firestore, FirestoreCollections.STATES) as CollectionReference<State>))
          
          const newStates: State[] = []
          result.forEach(doc => newStates.push(new State(doc)))

          newStates.sort(sortByNameASC)

          setStates(newStates)
        } catch (e) {
          // console.log('STATES - ERROR')
          console.error(e)
        }
      })()
    }
  }, [states, firestore])

  useEffect(() => {
    if (isNil(categories)) {
      // console.log('CATEGORIES - FETCH')
      ;(async () => {
        try {
          const result = await getDocs(query(collection(firestore, FirestoreCollections.CATEGORIES) as CollectionReference<Category>, where('active', '==', true)))
          
          const newCategories: Category[] = []
          result.forEach(doc => newCategories.push(new Category(doc)))

          setCategories(newCategories)
        } catch (e) {
          // console.log('CATEGORIES - ERROR')
          console.error(e)
        }
      })()
    }
  }, [categories, firestore])

  useEffect(() => {
    if (permission && (!newItem || newItem.cloneId !== permission.itemRef.id)) {
      // console.log('NEW ITEM - FETCH PERMISSION')
      setLoading(true)

      permission.getItem().then(item => {
        if (!item) {
          setCloneItem(undefined)
          setLoading(false)
          return
        }

        setCloneItem(item)

        resetItemByClone(item)
        
        setLoading(false)
      }).catch(e => {
        console.error(e)
        setCloneItem(undefined)
        
        try {
          Analytics.logEvent('edit_item_error', {
            method: 'fetch_permission',
            itemId: permission.itemRef.id,
            permission: permission.level,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }

        setLoading(false)
      })
    }
  }, [permission, resetItemByClone])

  useEffect(() => {
    // console.log('NEW ITEM - USE EFFECT - SET GOOGLE API KEY')
    try {
      Location.setGoogleApiKey(Constants.manifest?.extra?.googleApiKey)
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('new_item_error', {
          method: 'setGoogleApiKey',
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  // console.log('NEW ITEM - RENDER')

  return (
    <View flex={1} width='full' overflow='hidden'>
      <ScrollView flex={1}>
        <VStack space={4} alignItems='center' bgColor={theme.colors.white} p={4} rounded='md'>
          <HStack space={2} width='full' justifyContent={'center'} alignItems='center'>
            <Box>
              {newItem.logo && newItem.logo.length > 0 ? (
                <Box position='relative'>
                  <Avatar
                    source={{ uri: newItem.logo }}
                    bgColor={theme.colors.white}
                    borderWidth={1}
                    borderColor={theme.colors.primary[800]}
                    size={'md'}
                    alignSelf={'center'}
                  >
                    <Text p={2} color={theme.colors.primary[800]} numberOfLines={1} ellipsizeMode='tail' isTruncated={true} fontSize={'xs'}>{newItem.name ?? ''}</Text>
                  </Avatar>

                  <IconButton 
                    icon={<Icon as={MaterialIcons} name='delete' size={4} />} 
                    colorScheme='error' 
                    onPress={() => handleRemoveLogo()} 
                    position='absolute' 
                    top={0} 
                    right={0} 
                    width={4}
                    height={4}
                    rounded={'xs'} 
                    padding={0}
                    variant='solid'
                  />
                </Box>
              ) : (
                <IconButton 
                  icon={
                    <Icon as={MaterialIcons} name='camera-alt' />
                  } 
                  colorScheme='primary' 
                  size='md'
                  rounded={'full'}
                  borderWidth={1}
                  borderColor={theme.colors.primary[800]}
                  onPress={() => handleOpenImageActionSheet('logo')}
                  isDisabled={isUploading}
                  variant='solid'
                />
              )}

              <Actionsheet isOpen={isOpen} onClose={onClose}>
                <Actionsheet.Content>
                  <Actionsheet.Item onPress={pickImage}>Escolher da Biblioteca</Actionsheet.Item>
                  <Actionsheet.Item onPress={takePhoto}>Abrir Camera</Actionsheet.Item>
                  <Actionsheet.Item onPress={onClose}>Cancelar</Actionsheet.Item>
                </Actionsheet.Content>
              </Actionsheet>
            </Box>

            <FormTextField
              isRequired={true}
              isInvalid={!(!submitted || isValidInput(newItem.name, 3))}
              setAttribute={(attr, value) => setNewItem({[attr]: value})}
              errorMessage='Por favor, providencie um título válido.'
              type='text'
              placeholder='Título - Ex.: Brasil Americano'
              attributeName='name'
              autoComplete='off'
            />
          </HStack>

          <EditItemBanner banner={newItem.banner} addBanner={handleOpenImageActionSheet} removeBanner={handleRemoveBanner} isLoading={isUploading} />

          <FormControl>
            <VStack space={2}>
              <FlatList
                data={['', '', '']}
                keyExtractor={(_, index) => index.toString()}
                horizontal={true}
                renderItem={(item) => <EditItemSlide index={item.index} slides={newItem.slides} addSlide={handleOpenImageActionSheet} removeSlide={handleRemoveSlide} isLoading={isUploading} />}
              />

              <FormControl.HelperText>Até, no máximo, 3 slides.</FormControl.HelperText>
            </VStack>
          </FormControl>

          <DescriptionTextArea 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
          />

          <HStack flexWrap={'wrap'} justifyContent='center' alignItems={'center'} space={2} marginBottom={2} w="full">
            <SelectCategory isInvalid={!(!submitted || !selectedCategory)} selectedValue={selectedCategoryId} setSelectedCategoryId={setSelectedCategoryId} categories={categories} />
          </HStack>

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='Telefone/Celular'
            type='text'
            placeholder='+14074700000'
            attributeName='phone'
            keyboardType='phone-pad'
            autoComplete='tel'
            leftIconFamily={FontAwesome}
            leftIconName='phone'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='SMS'
            type='text'
            placeholder='+14074700000'
            attributeName='sms'
            keyboardType='phone-pad'
            autoComplete='tel'
            leftIconFamily={FontAwesome5}
            leftIconName='sms'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='WhatsApp'
            type='text'
            placeholder='+14074700000'
            attributeName='whatsapp'
            keyboardType='phone-pad'
            autoComplete='tel'
            leftIconFamily={MaterialCommunityIcons}
            leftIconName='whatsapp'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            isInvalid={!(!submitted || isValidEmail(newItem.email))}
            label='Email'
            type='text'
            placeholder='exemplo@exemplo.com'
            attributeName='email'
            keyboardType='email-address'
            autoComplete='email'
            leftIconFamily={MaterialCommunityIcons}
            leftIconName='email'
            errorMessage='Por favor, insira um email válido.'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='Website'
            type='text'
            placeholder='https://exemplo.com/'
            attributeName='website'
            keyboardType='url'
            autoComplete='off'
            leftIconFamily={MaterialCommunityIcons}
            leftIconName='web'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='Instagram'
            type='text'
            placeholder='Somente o nickname do perfil no Instagram'
            attributeName='instagram'
            keyboardType='email-address'
            autoComplete='off'
            leftIconFamily={MaterialCommunityIcons}
            leftIconName='instagram'
            helperMessage='Somente o nickname do perfil no Instagram, sem o @.'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='Facebook'
            type='text'
            placeholder='Somente o nickname do perfil no Facebook'
            attributeName='facebook'
            keyboardType='email-address'
            autoComplete='off'
            leftIconFamily={Entypo}
            leftIconName='facebook'
            helperMessage='Somente o nickname do perfil no Facebook, sem o @.'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='YouTube'
            type='text'
            placeholder='c/NomeDoCanal'
            attributeName='youtube'
            keyboardType='url'
            autoComplete='off'
            leftIconFamily={Ionicons}
            leftIconName='logo-youtube'
            helperMessage='Somente a parte final da URL do canal. Ex.: c/BLOGamerosBR ou channel/UCwaOlgGFAJHD'
          />

          <HStack flexWrap={'wrap'} justifyContent='center' alignItems={'center'} space={2} marginTop={4} marginBottom={2} w="full">
            <SelectState 
              isRequired={submitted && !isEmpty(newItem.address1.trim())}
              isInvalid={!(!submitted || isValidState(newItem.address1, selectedState))}
              selectedValue={selectedStateId}
              setSelectedStateId={setSelectedStateId}
              states={states}
            />

            <SelectCity
              isRequired={submitted && !isEmpty(newItem.address1.trim())} 
              isInvalid={!(!submitted || isValidCity(selectedCityId, newItem.address1, selectedCity, newCityName))}
              selectedValue={selectedCityId}
              setSelectedCityId={setSelectedCityId}
              cities={cities}
            />
          </HStack>

          {selectedCityId === 'other' ? (
            <FormTextField 
              isRequired={true}
              isInvalid={!(!submitted || selectedCityId !== 'other' || isValidInput(newCityName, 1))}
              setAttribute={(_, value) => setNewCityName(value)}
              label='Nome da Cidade'
              type='text'
              attributeName='newCityName'
              autoComplete='postal-address-locality'
              errorMessage='Por favor, providencie um nome válido.'
            />
          ) : null}

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='Endereço 1'
            type='text'
            placeholder='4515 Avenue Brazil'
            attributeName='address1'
            autoComplete='postal-address'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='Endereço 2'
            type='text'
            placeholder='Suite 123'
            attributeName='address2'
            autoComplete='postal-address-extended'
          />

          <FormTextField 
            setAttribute={(attr, value) => setNewItem({[attr]: value})}
            label='Zip Code'
            type='text'
            placeholder='33333'
            attributeName='zipCode'
            autoComplete="postal-code"
            keyboardType='phone-pad'
          />

          <Button isLoading={loading} isLoadingText='Salvando' width={'full'} rounded='full' onPress={handleSubmitButtonClick}>
            Salvar
          </Button>

          <Button width={'full'} rounded='full' variant='outline' onPress={() => reset()} disabled={loading}>
            Limpar
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(NewItemPage);
