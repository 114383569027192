import { Box, Image, IconButton, Icon, Button } from 'native-base';
import React, { FC, memo, useMemo } from 'react';
import { minSlideWidth } from '../config';
import { MaterialIcons } from '@expo/vector-icons'
import { useWindowDimensions } from 'react-native';
import { getSlideSize } from '../helpers/image.helper';

interface EditItemSlideProps {
  slides: string[]
  index: number
  addSlide: (type: 'slides') => void
  removeSlide: (index: number) => void,
  isLoading?: boolean
}

const EditItemSlide: FC<EditItemSlideProps> = ({
  slides,
  index,
  addSlide,
  removeSlide,
  isLoading
}) => {

  const { width: windowWidth } = useWindowDimensions()

  const slideWidth = useMemo<string>(() => {
    return `${getSlideSize((slides).length, windowWidth)}px`
  }, [slides, windowWidth])

  if (index > slides.length - 1) {
    return (
      <Button marginRight={2} isLoading={isLoading} isLoadingText='Subindo Arquivo' onPress={() => addSlide('slides')} variant='outline' width={slideWidth} height={[32, 64, 72, 96]} rounded='md'>
        Adicionar Slide
      </Button>
    )
  }

  return (
    <Box marginRight={2} position='relative' width={slideWidth}>
      <Image resizeMode='cover' source={{uri: slides[index]}} rounded='md' width={slideWidth} height={[32, 64, 72, 96]} />

      <IconButton icon={<Icon as={MaterialIcons} name='delete' />} colorScheme='error' onPress={() => removeSlide(index)} position='absolute' top={2} right={2} size='sm' variant='solid' rounded={'sm'} />
    </Box>
  );
}

export default memo(EditItemSlide);
