import { getStorage, ref, StorageError, uploadBytes, getDownloadURL } from 'firebase/storage'
import React, { useCallback } from 'react'
import { Box, Text, useTheme, useToast } from 'native-base'
import * as Analytics from 'expo-firebase-analytics'

const useStorage = () => {
  const storage = getStorage()
  const toast = useToast()
  const theme = useTheme()

  const toastAuthError = useCallback((error?: StorageError | null) => {
    let errorMessage = ''
    
    switch (error?.code ?? '') {
      case 'storage/invalid-url':
      case 'storage/object-not-found':
      case 'storage/bucket-not-found':
      case 'storage/project-not-found':
        errorMessage = 'Ops! O arquivo não foi encontrado. Por favor, tente novamente ou contate nosso suporte.'
        break

      case 'storage/unauthenticated':
        errorMessage = 'Ops! Algo deu errado na sessão. Por favor, refaça o login e tente novamente.'
        break

      case 'storage/unauthorized':
        errorMessage = 'Ops! Parece que você não tem as permissões necessárias para esta ação. Por favor, tente novamente ou contate nosso suporte.'
        break

      case 'storage/server-file-wrong-size':
      case 'storage/cannot-slice-blob':
      case 'storage/no-default-bucket':
      case 'storage/invalid-argument':
      case 'storage/invalid-checksum':
        errorMessage = 'Ops! Ocorreu um erro durante o upload. Por favor, tente novamente ou contate nosso suporte.'
        break

      case 'storage/canceled':
        errorMessage = 'Ação cancelada.'
        break

      default:
        errorMessage = 'Ops! Um error ocorreu. Por favor, tente novamente ou contate nosso suporte.'
        break
    }

    if (toast) {
      try {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color={'white'}>{errorMessage}</Text>
            </Box>
          )
        })
      } catch (e) {
        alert(errorMessage)
      }
    }
  }, [toast, theme])

  const uploadBlob = useCallback(async (fileName: string, blob: Blob | Uint8Array | ArrayBuffer, output: string = 'logos/'): Promise<string> => {
    if (!storage) {
      try {
        Analytics.logEvent('upload_error', {
          method: 'upload',
          name: 'NO_STORAGE',
        })
      } catch (e) {
        console.error(e)
      }

      toastAuthError()
    }

    const fileRef = ref(storage, `${output}${fileName}`)

    try {
      await uploadBytes(fileRef, blob)
      return getDownloadURL(fileRef)
    } catch (e) {
      console.error(e);

      toastAuthError((e as any).code)
        
      try {
        Analytics.logEvent('signUp_error', {
          method: 'signUp',
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      throw e
    }
  }, [storage, toastAuthError])

  return {
    uploadBlob
  }
}

export default useStorage
