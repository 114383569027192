import React, { FC, memo, useMemo } from 'react';
import Item, { ItemStatus } from '../models/Item.class';
import { Alert, Avatar, Badge, Box, Heading, HStack, ScrollView, Text, useTheme, VStack } from 'native-base';
import ActionIconButtons from './ActionIconButtons';
import { User } from '../models/User.class';
import { isNil } from 'lodash';
import { DocumentReference } from 'firebase/firestore';

export const actionIconSize = 25

interface ListItemProps {
  userData?: User | null,
  item: Item,
  refreshItem: (itemRef: DocumentReference<Item>, replaceRef?: DocumentReference<Item>) => void
}

const ListItem: FC<ListItemProps> = ({userData, item, refreshItem}) => {
  const theme = useTheme()

  const statusColor = useMemo<string | undefined>(() => {
    switch(item.status) {
      case ItemStatus.ACTIVE:
        return 'success'
      
      case ItemStatus.INACTIVE:
        return 'warning'
      
      case ItemStatus.PENDING_APPROVAL:
        return 'info'

      case ItemStatus.REJECTED:
        return 'error'

      default:
        return undefined
    }
  }, [item.status])

  return (
    <VStack space={2} mx={4} p={4} bgColor={theme.colors.white} rounded='md' marginBottom={2} borderWidth={['new', 'change'].includes(item.mode) ? 2 : 0} borderColor={item.mode === 'new' ? theme.colors.primary[400] : theme.colors.yellow[400]}>
      <HStack width={'full'} justifyContent="space-between" alignItems={'center'} space={2} borderBottomWidth={1} borderBottomColor={theme.colors.primary[800]} py={2}>
        {!isNil(item.logo) && item.logo.length > 0 ? (
          <Avatar 
            source={{uri: item.logo}}
            bgColor={theme.colors.white}
            borderWidth={1}
            borderColor={theme.colors.primary[800]}
            size='md'
          />
        ) : null}

        <Heading size="sm" fontWeight='normal' flex={1}>{item.name}</Heading>
        
        {['new', 'change'].includes(item.mode) ? (
          <Badge variant='subtle' rounded={'full'} colorScheme={item.mode === 'new' ? 'primary' : 'warning'}>{item.mode === 'new' ? 'Novo' : 'Alteração'}</Badge>
        ): null}

        <Badge variant='subtle' rounded={'full'} colorScheme={statusColor}>{item.status}</Badge>
      </HStack>

      {!item.active && !item.waitingForApproval && item.rejectedReason && item.rejectedReason.trim().length > 0 ? (
        <Alert colorScheme='error' status="error" width='full' variant='subtle'>
          <VStack space={2} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
              <HStack space={2} flexShrink={1} alignItems="center">
                <Alert.Icon />
                <Text color='coolGray.800'>
                  {item.rejectedReason}
                </Text>
              </HStack>
            </HStack>
          </VStack>
        </Alert>
      ) : null}

      {item.description && item.description.length > 0 ? (
        <Text fontWeight={'thin'} fontSize='xs'>{item.description}</Text>
      ) : null}

      {item.addressLine && item.addressLine.length > 0 ? (
        <VStack>
          <Text fontSize={'xs'}>Endereço:</Text>
          <Text fontSize={'xs'} fontWeight='thin'>{item.addressLine}</Text>
        </VStack>
      ) : null}

      {item.subCategories && item.subCategories.length > 0 ? (
        <VStack space={2}>
          <Text fontSize={'xs'}>Sub-categorias:</Text>

          <HStack alignItems={'center'} space={2} flexWrap='wrap'>
            {item.subCategories.map(subCategory => (
              <Badge key={subCategory.name} variant='subtle' rounded={'full'}>{subCategory.name}</Badge>
            ))}
          </HStack>
        </VStack>
      ) : null}

      {userData ? (
        <Box
          bgColor={theme.colors.white}
          width='full'
        >
          <ScrollView
            horizontal={true}
            style={{
              padding: 8
            }}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
          >
            <ActionIconButtons
              userData={userData}
              item={item}
              refreshItem={refreshItem}
              noMarginBottom={true}
              showLabels={true}
            />
          </ScrollView>
        </Box>
      ) : null}
    </VStack>
  );
}

export default memo(ListItem);
