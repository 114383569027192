import Permission from "../../models/Permission.class";

export interface AuthState {
  loading: boolean
  isSignedIn?: boolean
  currentRequestId?: string
  error?: string
  permissions?: Permission[]
}

export default {
  loading: false,
} as AuthState;
