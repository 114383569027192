import { collection, CollectionReference, getDocs, getFirestore } from 'firebase/firestore';
import { isNil, isUndefined } from 'lodash';
import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import { User } from '../../../models/User.class';
import * as Analytics from 'expo-firebase-analytics'
import { Box, FlatList, HStack, Pressable, ScrollView, Text, View } from 'native-base';
import ListUserItem from '../../../components/ListUserItem';
import useDebounce from '../../../hooks/useDebounce';
import SearchBar from '../../../components/SearchBar';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { UsersStackParamList } from '../navigators/UsersNavigator';

const Users: FC = () => {
  const firestore = getFirestore()
  const navigation = useNavigation<NativeStackNavigationProp<UsersStackParamList>>()

  const [ users, setUsers ] = useState<User[] | null>();
  const [filteredUsers, setFilteredUsers] = useState<User[] | null>()

  const [ searchQuery, setSearchQuery ] = useState('');
  const [ error, setError ] = useState<Error>()

  const { execute: debouncedSearch } = useDebounce(300, (term: string) => {
    if (!isNil(term)) {
      setSearchQuery(term.trim().toLowerCase())
    }
  });

  const handleSearchQueryChange = useCallback((query: string) => {
    debouncedSearch(query)
  }, [debouncedSearch])

  useFocusEffect(useCallback(() => {
    if (isUndefined(users) && firestore) {
      setError(undefined)

      getDocs(collection(firestore, 'users') as CollectionReference<User>).then((result) => {
        setError(undefined)
        const newUsers: User[] = []

        result.forEach(doc => newUsers.push(new User(doc)))

        setUsers(newUsers)
      }).catch((e) => {
        console.error(e)
        setError(e)
        setUsers(null)

        try {
          Analytics.logEvent('users_error', {
            method: 'fetch_users',
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
      })
    }

    return () => {
      setUsers([])
    }
  }, [ firestore ]));

  useEffect(() => {
    if (users) {
      const newFilteredItems = users.reduce((result: User[], user: User) => {
        if (
          searchQuery &&
          searchQuery.trim().length > 0 &&
          !user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) &&
          !user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) &&
          !user.email.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return result
        }

        result.push(user)

        return result
      }, [])

      setFilteredUsers(newFilteredItems)
    }
  }, [users, searchQuery])

  if (!isNil(error)) {
    return (
      <Text fontSize={'md'}>
        {error.name} {error.message}
      </Text>
    )
  }

  return (
    <View flex={1} width='full' overflow='hidden'>
      <HStack space={2} p={2} justifyContent='center' alignItems='center' w='full'>
        <SearchBar placeholder={`Buscar usuário`} flex={1} onChange={handleSearchQueryChange} />
      </HStack>
      
      <ScrollView flex={1} padding={4}>
        <FlatList
          data={filteredUsers}
          keyExtractor={(user) => user.id}
          renderItem={({item: user}) => (
            <Box marginBottom={2}>
              {/* <Pressable onPress={() => navigation.push('userItems', {id: user.id})}> */}
                <ListUserItem user={user} />
              {/* </Pressable> */}
            </Box>
          )}
        />
      </ScrollView>
    </View>
  );
}

export default memo(Users);
