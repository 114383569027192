import { collection, CollectionReference, DocumentReference, DocumentSnapshot, Firestore, getDocs, query, where } from "firebase/firestore";
import { FirestoreSnapshot } from "./FirestoreSnapshot.class";
import Item from "./Item.class";
import * as Analytics from 'expo-firebase-analytics'

export interface SubCategoryRef {
  name: string
  ref: DocumentReference<SubCategory>
}

export interface SubCategory {
  id: string
  name: string
  active: boolean
}

export interface CategoryRef {
  ref: DocumentReference<Category>
  name: string
}

export class Category extends FirestoreSnapshot<Category> {
  name: string;
  icon: string;
  order: number;
  active: boolean

  items: Item[] = []

  subCategories: SubCategory[] = []

  constructor(doc: DocumentSnapshot<Category>) {
    super(doc)

    const data = doc.data()!
    this.name = data.name
    this.icon = data.icon
    this.order = data.order
    this.active = data.active
  }

  async fetchSubCategories(): Promise<SubCategory[]> {
    this.subCategories = []
    
    try {
      const result = await getDocs(query(collection(this.snapshot.ref.firestore, `${this.snapshot.ref.path}/sub-categories`) as CollectionReference<SubCategory>, where('active', '==', true)))
      
      result.forEach(doc => this.subCategories.push({
        ...doc.data(),
        id: doc.id,
      }))
    } catch (e) {
      console.error(e)
    }

    return this.subCategories
  }

  async fetchItems(): Promise<Item[]> {
    try {
      const result = await getDocs(query(collection(this.snapshot.ref.firestore, 'items') as CollectionReference<Item>, where('category.ref', '==', this.snapshot.ref), where('active', '==', true)))

      result.forEach(doc => this.items.push(new Item(doc)))
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('category_error', {
          method: 'fetchItems',
          categoryId: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }

    return this.items
  }
}
