import { FC, Key, memo, useCallback } from 'react';
import { FormControl, VStack, Input, Icon, theme, WarningOutlineIcon } from 'native-base';
import { isNil } from 'lodash';
import { KeyboardTypeOptions } from 'react-native';

interface FormTextFieldProps {
  isInvalid?: boolean;
  attributeName: string;
  setAttribute: (attribute: string, value: string) => void;
  label?: string;
  errorMessage?: string;
  helperMessage?: string;
  type: 'text' | 'password';
  placeholder?: string;
  keyboardType?: KeyboardTypeOptions | undefined;
  autoComplete?:
    | 'birthdate-day'
    | 'birthdate-full'
    | 'birthdate-month'
    | 'birthdate-year'
    | 'cc-csc'
    | 'cc-exp'
    | 'cc-exp-day'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-number'
    | 'email'
    | 'gender'
    | 'name'
    | 'name-family'
    | 'name-given'
    | 'name-middle'
    | 'name-middle-initial'
    | 'name-prefix'
    | 'name-suffix'
    | 'password'
    | 'password-new'
    | 'postal-address'
    | 'postal-address-country'
    | 'postal-address-extended'
    | 'postal-address-extended-postal-code'
    | 'postal-address-locality'
    | 'postal-address-region'
    | 'postal-code'
    | 'street-address'
    | 'sms-otp'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-device'
    | 'username'
    | 'username-new'
    | 'off'
    | undefined;
  leftIconFamily?: any;
  leftIconName?: string
  isRequired?: boolean;
  initialValue?: string;
  value?: string;
}

const FormTextField: FC<FormTextFieldProps> = ({isRequired, isInvalid, setAttribute, label, type, placeholder, attributeName, keyboardType, autoComplete, errorMessage, leftIconFamily, leftIconName, helperMessage, initialValue, value}) => {

  // console.log('FormTextField RENDER', attributeName)
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired} flex={1} marginBottom={(errorMessage && isInvalid) || helperMessage ? 4 : undefined}>
      <VStack>
        {!isNil(label) ? <FormControl.Label>{label}</FormControl.Label> : null}
        <Input 
          type={type} 
          variant={'rounded'} 
          placeholder={placeholder}
          onChangeText={text => setAttribute(attributeName, text)} 
          keyboardType={keyboardType}
          autoComplete={autoComplete}
          InputLeftElement={leftIconFamily && leftIconName ? <Icon as={leftIconFamily} name={leftIconName} size={5} color={theme.colors.primary[800]} ml={2} /> : undefined}
          defaultValue={initialValue}
          value={value}
        />
        {!isNil(errorMessage) ? (
          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
        {!isNil(helperMessage) ? (
          <FormControl.HelperText>
            {helperMessage}
          </FormControl.HelperText>
        ) : null}
      </VStack>
    </FormControl>
  );
}

export default memo(FormTextField);
