import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC, memo } from 'react';
import AppHeader from '../../../components/AppHeader';
import { ItemMode } from '../../../models/Item.class';
import EditItem from '../views/EditItemPage';
import Home from '../views/Home'
import NewItem from '../views/NewItemPage';

export type DashboardStackParamList = {
  home: undefined;
  newitem?: {
    id?: string | null
  };
  edititem: {
    id: string,
    mode?: ItemMode
  }
  itemstatistics: {
    id: string
  }
};

const Stack = createNativeStackNavigator<DashboardStackParamList>()

const DashboardNavigator: FC = () => {
  return (
    <Stack.Navigator initialRouteName='home' screenOptions={{
      header: (props) => <AppHeader {...props} />
    }}>
      <Stack.Screen name="home" component={Home} options={{
        title: 'BrasilAmericano'
      }} />
      <Stack.Screen name="newitem" component={NewItem} options={{
        title: 'BrasilAmericano | Novo Serviço'
      }} />
      <Stack.Screen name="edititem" component={EditItem} options={{
        title: 'BrasilAmericano | Editar Serviço'
      }} />
    </Stack.Navigator>
  )
}

export default memo(DashboardNavigator)
