import { Avatar, HStack, useTheme, VStack, Text, Badge } from 'native-base';
import React, { FC, memo, useMemo } from 'react';
import { User } from '../models/User.class';

interface ListUserItemProps {
  user: User
}

const ListUserItem: FC<ListUserItemProps> = ({user}) => {
  const theme = useTheme()

  const nameInitials = useMemo<string>(() => {
    let initials = ''

    if (user.firstName && user.firstName.trim().length > 0) {
      initials = user.firstName.trim()[0].toUpperCase()
    }

    if (user.lastName && user.lastName.trim().length > 0) {
      initials += user.lastName.trim()[0].toUpperCase()
    }

    if (initials.length === 0) {
      initials = `${user.email[0]}${user.email[1]}`.toUpperCase()
    }

    return initials
  }, [user])

  return (
    <VStack width='full' space={2} p={2} bgColor={theme.colors.white} rounded='md'>
      <HStack space={2}>
        <Avatar size='md' bgColor={theme.colors.primary[800]} source={{uri: user.photo}}>
          <Text fontSize={'sm'} color={theme.colors.white}>{nameInitials}</Text>
        </Avatar>

        <VStack height={'full'} justifyContent='center'>
          <Text fontSize='md' color={theme.colors.primary[600]} bold>{user.firstName ?? ''}{user.lastName ? ` ${user.lastName}` : ''}</Text>
          <Text fontSize='xs' color={theme.colors.gray[500]}>{user.email}</Text>
        </VStack>
      </HStack>

      <HStack width={'full'} space={2}>
        <Badge colorScheme="info" variant={'subtle'} rounded='full'>
          {user.role}
        </Badge>

        <Badge variant={'subtle'} rounded='full'>
          {`Permissions: ${user.permissions?.length ?? 0}`}
        </Badge>
      </HStack>
    </VStack>
  );
}

export default memo(ListUserItem);
