import { doc, Firestore, getDoc } from "firebase/firestore"
import { isNil, random } from "lodash"
import slugify from "slugify"
import City, { CityRef } from "../models/City.class"
import { FirestoreCollections } from "../models/FirestoreCollections.enum"
import Item from "../models/Item.class"
import { NewItem } from "../views/protected/views/NewItemPage"

export const getValidSlug = async (newItem: NewItem | Item, firestore: Firestore, selectedCity?: City | CityRef | null, newCityName?: string): Promise<string> => {
  const possibleSlugs = [
    newItem.instagram?.trim(),
    slugify(newItem.name, {
      replacement: '',
      remove: /[*+~.()'"!:@]/g,
      lower: true,
      strict: true,
      locale: 'en',
      trim: true
    }),
    slugify(`${newItem.name}${selectedCity ? selectedCity.name : (newCityName ?? '')}`, {
      replacement: '',
      remove: /[*+~.()'"!:@]/g,
      lower: true,
      strict: true,
      locale: 'en',
      trim: true
    }),
    slugify(`${newItem.name}${selectedCity ? selectedCity.name : (newCityName ?? '')}${random(1, 9999)}`, {
      replacement: '',
      remove: /[*+~.()'"!:@]/g,
      lower: true,
      strict: true,
      locale: 'en',
      trim: true
    }),
    newItem.facebook?.trim(),
  ]

  for (const possibleSlug of possibleSlugs) {
    if (isNil(possibleSlug) || (possibleSlug.trim().length === 0)) {
      continue;
    }

    try {
      const itemRef = doc(firestore as any, FirestoreCollections.ITEMS, possibleSlug)
      const existingItemDoc = await getDoc(itemRef)

      if (!existingItemDoc.exists()) {
        return possibleSlug
      }
    } catch {
      continue
    }
  }

  return '' // TODO: add a while-loop to append number one plus one
}
