import { collection, CollectionReference, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { isNil } from 'lodash';
import { Button, ScrollView, View, VStack } from 'native-base';
import React, { FC, memo, useState, useEffect, useCallback } from 'react';
import Item from '../../../models/Item.class';
import State from '../../../models/State.class';
import * as Location from 'expo-location'
import Constants from 'expo-constants'
import * as Analytics from 'expo-firebase-analytics'

const Functions: FC = () => {
  const firestore = getFirestore()

  const [isLoading, setLoading] = useState<boolean>(true)

  const [states, setStates] = useState<State[]>()
  const [items, setItems] = useState<Item[]>()
  const [itemsWithoutCoords, setItemsWithoutCoords] = useState<Item[]>([])
  const [itemsWithoutGeoHash, setItemsWithoutGeoHash] = useState<Item[]>([])

  const handleUpdateStatesCoordsAndGeoHashButtonClick = useCallback(async () => {
    if (states && states.length > 0) {
      setLoading(true)

      await Promise.all(states.map(async state => {
        await state.getCoords()
        await state.updateGeoHash()
      }))

      setLoading(false)
    }
  }, [states])

  const handleUpdateCitiesCoordsAndGeoHashButtonClick = useCallback(async () => {
    if (states && states.length > 0) {
      setLoading(true)

      await Promise.all(states.map(async state => {
        if (state.cities && state.cities.length > 0) {
          return Promise.all(state.cities.map(async city => {
            await city.getCoords()
            await city.updateGeoHash()
          }))
        }
      }))

      setLoading(false)
    }
  }, [states])

  const handleUpdateCoordsButtonClick = useCallback(async () => {
    if (itemsWithoutCoords.length > 0) {
      setLoading(true)

      await Promise.all(itemsWithoutCoords.map(item => item.getCoords()))

      setItemsWithoutCoords([])

      setLoading(false)
    }
  }, [itemsWithoutCoords])

  const handleUpdateGeoHashButtonClick = useCallback(async () => {
    if (itemsWithoutGeoHash.length > 0) {
      setLoading(true)

      await Promise.all(itemsWithoutGeoHash.map(item => item.updateGeoHash()))

      setItemsWithoutGeoHash([])

      setLoading(false)
    }
  }, [itemsWithoutGeoHash])

  useEffect(() => {
    if (items === undefined) {
      getDocs(query(collection(firestore, 'items') as CollectionReference<Item>, where('active', '==', true))).then(results => {
        const newItems: Item[] = []

        results.forEach(doc => newItems.push(new Item(doc)))

        setItems(newItems)

        setLoading(false)
      }).catch(e => {
        console.error(e)
        alert('Error querying items')
      })
    } else {
      setItemsWithoutCoords(items.filter(item => !isNil(item.state) && !isNil(item.address1) && item.address1.trim().length > 0 && isNil(item.coords)))
      setItemsWithoutGeoHash(items.filter(item => !isNil(item.coords) && isNil(item.geoHash)))
    }
  }, [items, firestore])

  useEffect(() => {
    if (isNil(states)) {
      ;(async () => {
        try {
          const result = await getDocs(query(collection(firestore, 'states') as CollectionReference<State>))
          
          const newStates: State[] = []
          result.forEach(doc => newStates.push(new State(doc)))

          setStates(await Promise.all(newStates.map(async state => {
            await state.fetchCities()
            return state
          })))
        } catch (e) {
          console.error(e)
          console.log('Error querying States')
        }
      })()
    }
  }, [states, firestore])

  useEffect(() => {
    try {
      Location.setGoogleApiKey(Constants.manifest?.extra?.googleApiKey)
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('edit_item_error', {
          method: 'setGoogleApiKey',
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  return (
    <View flex={1} width='full' overflow='hidden'>
      <ScrollView flex={1} padding={4}>
        <VStack width={'full'} space={4} alignItems='center'>
          <Button isLoading={isLoading} width={'full'} rounded='full' onPress={handleUpdateCoordsButtonClick} disabled={itemsWithoutCoords.length === 0}>
            {`Atualizar Coords (${itemsWithoutCoords.length})`}
          </Button>

          <Button isLoading={isLoading} width={'full'} rounded='full' onPress={handleUpdateGeoHashButtonClick} disabled={itemsWithoutGeoHash.length === 0}>
            {`Atualizar GeoHash (${itemsWithoutGeoHash.length})`}
          </Button>

          <Button isLoading={isLoading} width={'full'} rounded='full' onPress={handleUpdateStatesCoordsAndGeoHashButtonClick}>
            {`Atualizar Estados: Coords & GeoHash`}
          </Button>

          <Button isLoading={isLoading} width={'full'} rounded='full' onPress={handleUpdateCitiesCoordsAndGeoHashButtonClick}>
            {`Atualizar Cidades: Coords & GeoHash`}
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(Functions);
