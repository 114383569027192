import React, { FC, memo, useCallback, useState } from 'react';
import { ScrollView, VStack, Image, FormControl, Input, WarningOutlineIcon, Button, useToast, Box, useTheme, View, Text } from 'native-base';
import useAuth from '../../hooks/useAuth';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { isValidEmail } from '../../helpers/validate.helper';
import { RootStackParamList } from '../NavigationContainerManager';

const ResetPassword: FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const toast = useToast()
  const theme = useTheme()

  const [email, setEmail] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  
  const {resetPassword} = useAuth()

  const goToSignIn = () => {
    navigation.navigate('signin')
  }

  const handleResetPasswordClick = useCallback(async () => {
    setSubmitted(true)
    setLoading(true)

    if (isValidEmail(email)) {
      try {
        await resetPassword(email)

        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color='white'>Um email para redefinição de senha foi enviado!</Text>
            </Box>
          )
        })

        goToSignIn()
      } catch {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [email, resetPassword, navigation, toast, theme])
  
  return (
    <View flex={1} width='full' overflow='hidden'>
      <ScrollView flex={1} padding={4}>
        <VStack space={4} alignItems='center'>
          <Image source={require('../../assets/logo.png')} width={120} height={120} resizeMode='contain' />

          <FormControl isRequired isInvalid={submitted && !isValidEmail(email)}>
            <VStack>
              <Input type="text" keyboardType='email-address' autoComplete='email' variant={'rounded'} placeholder='Email' value={email} onChangeText={text => setEmail(text)} />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Por favor, insira um email válido.
              </FormControl.ErrorMessage>
            </VStack>
          </FormControl>

          <Button isLoading={loading} isLoadingText='Entrando' width={'full'} rounded='full' onPress={handleResetPasswordClick}>
            Redefinir Senha
          </Button>

          <Button width={'full'} rounded='full' variant='outline' onPress={goToSignIn} disabled={loading}>
            Cancelar
          </Button>
        </VStack>
      </ScrollView>
    </View>
  );
}

export default memo(ResetPassword);
