export const getSlideSize = (slidesQty: number, totalWidth: number, additionalMargin: boolean = false): number => {
  if (slidesQty <= 0) {
    slidesQty = 1
  }

  let margin = (4 * 4)
  if (additionalMargin) {
    margin *= 2
  }

  // const finalSlideQty = slidesQty
  const finalSlideQty = 3

  return (totalWidth - margin - ((finalSlideQty - 1) * (2 * 4))) / finalSlideQty
}
