import { DocumentSnapshot } from "firebase/firestore";

export class FirestoreSnapshot<T> {
  readonly id: string

  constructor(
    readonly snapshot: DocumentSnapshot<T>
  ) {
    this.id = snapshot.id
  }
}
