import { getCityCoords, getGeoHash } from './../helpers/coords.helper';
import { DocumentReference, DocumentSnapshot, GeoPoint, runTransaction } from "firebase/firestore"
import { FirestoreSnapshot } from "./FirestoreSnapshot.class"
import { StateRef } from "./State.class"
import * as Analytics from 'expo-firebase-analytics'

export interface CityRef {
  ref: DocumentReference<City>
  name: string
}

export default class City extends FirestoreSnapshot<City> {
  name: string
  state: StateRef
  coords?: GeoPoint | null
  geoHash?: string | null
 
  constructor(doc: DocumentSnapshot<City>) {
    super(doc)

    const data = doc.data()!
    this.name = data.name
    this.state = data.state
  }

  async getCoords(): Promise<GeoPoint | null> {
    if (this.coords) {
      return this.coords
    }

    try {
      this.coords = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          console.error('city - getCoords - not found')
          return null
        }

        const newCoords = await getCityCoords(this.state.name, this.name)
        await transaction.update(this.snapshot.ref, {
          coords: newCoords,
        })

        return newCoords
      })
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('city_error', {
          method: 'city_getCoords',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      return null
    }
  }

  async updateGeoHash(): Promise<string | null | undefined> {
    if (this.geoHash) {
      return this.geoHash
    }

    if (!this.coords) {
      return null
    }

    try {
      this.geoHash = await runTransaction(this.snapshot.ref.firestore, async (transaction) => {
        const doc = await transaction.get(this.snapshot.ref)
        if (!doc.exists()) {
          console.error('city - updateGeoHash - not found')
          return this.geoHash
        }

        const newGeoHash = getGeoHash(this.coords!)
        await transaction.update(this.snapshot.ref, {
          geoHash: newGeoHash,
        })

        return newGeoHash
      })
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('city_error', {
          method: 'city_updateGeoHash',
          id: this.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      return this.geoHash
    }
  }
}
