import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { deleteDoc, doc, DocumentReference, getDoc, setDoc, updateDoc, WithFieldValue } from "firebase/firestore"
import { isNil } from "lodash"
import { AlertDialog, Box, Button, HStack, IconButton, Text, useTheme, useToast } from "native-base"
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getIcon } from "../helpers/icon.helper"
import Item from "../models/Item.class"
import { User } from "../models/User.class"
import { DashboardStackParamList } from "../views/protected/navigators/DashboardNavigator"
import * as Analytics from 'expo-firebase-analytics'
import Permission from "../models/Permission.class"
import { useSelector } from "react-redux"
import { getPermissions } from "../redux/auth/selectors"
import { isValidItem } from "../helpers/validate.helper"
import { getValidSlug } from "../helpers/slug.helper"
import { FirestoreCollections } from "../models/FirestoreCollections.enum"
import { getCoords, getGeoHash } from "../helpers/coords.helper"
import * as Location from 'expo-location'
import Constants from 'expo-constants'
import moment from "moment"

interface IActionIcon {
  name: string
  iconName: string,
  action: () => void,
  condition: boolean,
  color?: string
}

interface ActionIconButtonsProps {
  userData: User,
  item: Item
  refreshItem: (itemRef: DocumentReference<Item>, replaceRef?: DocumentReference<Item>) => void
  showLabels?: boolean
  noMarginBottom?: boolean
}

const ActionIconButtons: FC<ActionIconButtonsProps> = ({
  userData,
  item,
  refreshItem,
  showLabels,
  noMarginBottom
}) => {
  const theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<DashboardStackParamList>>()
  const toast = useToast()

  const permissions = useSelector(getPermissions)

  const cancelRef = useRef<typeof Button>(null)
  const [isAlertDialogOpen, setAlertDialogOpen] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const permission = useMemo<Permission | undefined>(() => {
    return permissions?.find(permission => permission.itemRef.id === item.id)
  }, [permissions, item])

  const canUserManageItem = useMemo<boolean>(() => {
    return (userData.role === 'admin' || ['owner', 'admin'].includes(permission?.level ?? '') || (item.mode === 'new' && item.authorId === userData.id))
  }, [userData, permission, item])

  const statusAction = useMemo<'activate' | 'deactivate' | 'delete' | 'none'>(() => {
    if (item.active && canUserManageItem) {
      return 'deactivate'
    }

    if (!item.active && item.mode === 'item' && isNil(item.rejectedReason) && canUserManageItem) {
      return 'activate'
    }

    if (isNil(item.rejectedReason) && ['new', 'change'].includes(item.mode) && canUserManageItem) {
      return 'delete'
    }

    return 'none'
  }, [item, canUserManageItem])
  
  const handleAlertDialogClose = useCallback(() => {
    if (isLoading) {
      return
    }
    setAlertDialogOpen(false)
  }, [isLoading])

  const handleAlertDialogConfirm = useCallback(() => {
    if (!canUserManageItem || isLoading) {
      return
    }
    
    setLoading(true)

    try {
      ;(async () => {
        if (statusAction === 'delete') {
          await deleteDoc(item.snapshot.ref)
        } else {
          await updateDoc<Item>(item.snapshot.ref, {
            active: statusAction === 'activate' ? true : false
          })
        }

        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4}>
              <Text color={'white'}>{`Item ${statusAction === 'delete' ? 'excluido' : 'alterado'} com sucesso!`}</Text>
            </Box>
          )
        })

        if (refreshItem) {
          refreshItem(item.snapshot.ref)
        }
      })()
    } catch (e) {
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Ops! Ocorreu um erro. Por favor, tente novamente. Caso o erro persista, por favor, entre em contato com nossa equipe.</Text>
          </Box>
        )
      })

      try {
        Analytics.logEvent('item_actionbutton_error', {
          method: statusAction,
          itemId: item.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    } finally {
      setLoading(false)
    }
  }, [statusAction, canUserManageItem, isLoading, toast, refreshItem])

  const fetchAuthorData = useCallback(async (authorRef: DocumentReference<User>): Promise<User | null> => {
    try {
      const authorDoc = await getDoc(authorRef)

      if (!authorDoc.exists()) {
        throw new Error('AUTHOR_NOT_EXISTS')
      }

      return new User(authorDoc)
    } catch (e) {
      console.log('FETCH USER DATA - ERROR')
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Ops! Não foi possível carregar os dados do autor. Por favor, tente novamente mais tarde.</Text>
          </Box>
        )
      })

      try {
        Analytics.logEvent('item_actionbutton_error', {
          method: 'fetchAuthorData',
          itemId: item.id,
          authorId: item.authorId,
          adminId: userData.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      return null
    }
  }, [item, userData])

  const removeItemForApproval = useCallback(async () => {
    try {
      await deleteDoc(item.snapshot.ref)
    } catch (e) {
      console.log('REMOVE ITEM FOR APPROVAL - ERROR')
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Ops! Não foi possível deletar o item de aprovação. Por favor, tente novamente mais tarde.</Text>
          </Box>
        )
      })

      try {
        Analytics.logEvent('item_actionbutton_error', {
          method: 'removeItemForApproval',
          itemId: item.id,
          authorId: item.authorId,
          adminId: userData.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      return null
    }
  }, [item, userData])

  const registerNewItemPermission = useCallback(async (approvedItemRef: DocumentReference<Item>, authorRef: DocumentReference<User>) => {
    try {
      const permissionCreatedAt = moment().utc().toDate() 

      const itemPermissionRef = doc(approvedItemRef.firestore, approvedItemRef.path, FirestoreCollections.PERMISSIONS, authorRef.id) as DocumentReference<Permission>

      await setDoc(itemPermissionRef, {
        itemRef: approvedItemRef,
        userRef: authorRef,
        level: 'owner',
        createdAt: permissionCreatedAt,
        createdBy: null
      } as WithFieldValue<Permission>)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Nova permissão do item registrada com sucesso!</Text>
          </Box>
        )
      })

      try {
        const userPermissionRef = doc(approvedItemRef.firestore, authorRef.path, FirestoreCollections.PERMISSIONS, approvedItemRef.id) as DocumentReference<Permission>

        await setDoc(userPermissionRef, {
          itemRef: approvedItemRef,
          userRef: authorRef,
          level: 'owner',
          createdAt: permissionCreatedAt,
          createdBy: null
        } as WithFieldValue<Permission>)
  
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color={'white'}>Nova permissão de usuário registrada com sucesso!</Text>
            </Box>
          )
        })
      } catch (e) {
        console.log('NEW USER PERMISSION - ERROR')
        console.error(e)

        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color={'white'}>Ops! Não foi possível registar a nova permissão do usuário. Por favor, tente novamente mais tarde.</Text>
            </Box>
          )
        })

        try {
          Analytics.logEvent('item_actionbutton_error', {
            method: 'registerNewItemPermission',
            itemId: item.id,
            authorId: authorRef.id,
            adminId: userData.id,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }
      }
    } catch (e) {
      console.log('NEW ITEM PERMISSION - ERROR')
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Ops! Não foi possível registar a nova permissão do item. Por favor, tente novamente mais tarde.</Text>
          </Box>
        )
      })

      try {
        Analytics.logEvent('item_actionbutton_error', {
          method: 'registerNewItemPermission',
          itemId: item.id,
          authorId: authorRef.id,
          adminId: userData.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [toast, userData])

  const createItem = useCallback(async () => {
    if (!userData) {
      return 
    }

    setLoading(true)

    if (!isValidItem(item)) {
      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4}>
            <Text color='white'>Por favor, corrija os campos inválidos</Text>
          </Box>
        )
      })

      setLoading(false)
      return;
    }

    const slug = await getValidSlug(item, item.snapshot.ref.firestore, item.city)

    if (!slug || slug.length === 0) {
      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4}>
            <Text color={'white'}>Não foi possível encontrar um SLUG válido. Por favor, tente novamente.</Text>
          </Box>
        )
      })

      setLoading(false)
      return
    }

    try {
      const approvedItemRef = doc(item.snapshot.ref.firestore, FirestoreCollections.ITEMS, slug) as DocumentReference<Item>

      let authorRef: DocumentReference<User> | undefined = undefined
      let authorData: User | null = null
      let isAuthorAdmin = true

      if (!isNil(item.authorId)) {
        try {
          authorRef = doc(approvedItemRef.firestore, FirestoreCollections.USERS, item.authorId) as DocumentReference<User>
          authorData = await fetchAuthorData(authorRef)
          isAuthorAdmin = isNil(authorData) || ['admin', 'mod'].includes(authorData.role)
        } catch (e) {
          console.log('FETCH AUTHOR DATA BLOCK - ERROR')
          console.error(e)

          try {
            Analytics.logEvent('item_actionbutton_error', {
              method: 'fetchAuthorDataBlock',
              itemId: item.id,
              authorId: item.authorId,
              adminId: userData.id,
              name: (e as Error).name ?? 'Error',
              message: (e as Error).message ?? 'none',
              code: (e as any).code ?? 'none'
            })
          } catch (e) {
            console.error(e)
          }
        }
      }

      const coords = await getCoords(item.state?.name ?? '', item.address1 ?? '', item.address2 ?? '', item.zipCode ?? '', item.city?.name ?? '')
      const geoHash = getGeoHash(coords)

      await setDoc(approvedItemRef, {
        name: item.name,
        nameLowerCase: item.name.replaceAll("'", '').replaceAll('"', '').toLowerCase(),
        description: item.description.trim(),
        active: true,
        waitingForApproval: false,
        relevance: 0,
        membersQuantity: 0,
        verified: isAuthorAdmin ? null : true,

        logo: !isNil(item.logo) ? item.logo.trim().length > 0 ? item.logo.trim() : null : null,
        banner: !isNil(item.banner) ? item.banner.trim().length > 0 ? item.banner.trim() : null : null,
        slides: item.slides,
        phone: !isNil(item.phone) ? item.phone.trim().length > 0 ? item.phone.trim() : null : null,
        sms: !isNil(item.sms) ? item.sms.trim().length > 0 ? item.sms.trim() : null : null,
        whatsapp: !isNil(item.whatsapp) ? item.whatsapp.trim().length > 0 ? item.whatsapp.trim() : null : null,
        email: !isNil(item.email) ? item.email.trim().length > 0 ? item.email.trim() : null : null,
        website: !isNil(item.website) ? item.website.trim().length > 0 ? item.website.trim() : null : null,

        facebook: !isNil(item.facebook) ? item.facebook.trim().length > 0 ? item.facebook.trim() : null : null,
        instagram: !isNil(item.instagram) ? item.instagram.trim().length > 0 ? item.instagram.trim() : null : null,
        youtube: !isNil(item.youtube) ? item.youtube.trim().length > 0 ? item.youtube.trim() : null : null,

        address1: !isNil(item.address1) ? item.address1.trim().length > 0 ? item.address1.trim() : null : null,
        address2: !isNil(item.address2) ? item.address2.trim().length > 0 ? item.address2.trim() : null : null,
        zipCode: !isNil(item.zipCode) ? item.zipCode.trim().length > 0 ? item.zipCode.trim() : null : null,
        coords,
        geoHash,

        createdAt: moment().utc().toDate(),
        
        state: item.state,
        city: item.city,
        category: item.category,
        subCategories: item.subCategories ?? [],
      } as unknown as WithFieldValue<Item>)

      try {
        const approvedItemDoc = await getDoc(approvedItemRef)

        if (!approvedItemDoc.exists()) {
          toast.show({
            placement: 'bottom',
            render: () => (
              <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
                <Text color={'white'}>Ops! Ocorreu um erro ao tentar obter o novo Item. Por favor, verifique se o mesmo foi salvo.</Text>
              </Box>
            )
          })
    
          setLoading(false)
          return
        } else {
          toast.show({
            placement: 'bottom',
            render: () => (
              <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4} color='white'>
                <Text color={'white'}>Parabéns! O item foi approvado com sucesso e já deve aparecer listado na categoria(s) selecionada(s)!</Text>
              </Box>
            )
          })

          if (!isAuthorAdmin && !isNil(authorRef)) {
            await registerNewItemPermission(approvedItemRef, authorRef)
          }

          await removeItemForApproval()

          setLoading(false)
          refreshItem(approvedItemRef, item.snapshot.ref)
          return
        }
      } catch (e) {
        console.log('FETCH APPROVED ITEM - ERROR')
        console.error(e)
  
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color={'white'}>Ops! Ocorreu um erro ao tentar obter o novo Item. Por favor, tente novamente.</Text>
            </Box>
          )
        })

        try {
          Analytics.logEvent('item_actionbutton_error', {
            method: 'createItem',
            itemId: item.id,
            authorId: item.authorId,
            adminId: userData.id,
            name: (e as Error).name ?? 'Error',
            message: (e as Error).message ?? 'none',
            code: (e as any).code ?? 'none'
          })
        } catch (e) {
          console.error(e)
        }

        setLoading(false)
        return
      }
    } catch (e) {
      console.log('APPROVE NEW ITEM - ERROR')
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Ops! Ocorreu um erro ao tentar aprovar o Item. Por favor, tente novamente.</Text>
          </Box>
        )
      })

      try {
        Analytics.logEvent('item_actionbutton_error', {
          method: 'createItem',
          itemId: item.id,
          authorId: item.authorId,
          adminId: userData.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      setLoading(false)
      return
    }
  }, [item, userData, toast, registerNewItemPermission, removeItemForApproval, refreshItem, fetchAuthorData])

  const saveRequestedChanges = useCallback(async () => {
    if (!userData) {
      return 
    }

    setLoading(true)

    if (!isValidItem(item)) {
      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4}>
            <Text color='white'>Por favor, corrija os campos inválidos</Text>
          </Box>
        )
      })

      setLoading(false)
      return;
    }

    try {
      // fetch existing item and update it
      const existingItemRef = doc(item.snapshot.ref.firestore, FirestoreCollections.ITEMS, item.id) as DocumentReference<Item>

      const existingItem = await getDoc(existingItemRef)

      if (!existingItem.exists()) {
        toast.show({
          placement: 'bottom',
          render: () => (
            <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
              <Text color={'white'}>Ops! Ocorreu um erro ao tentar obter este item. Por favor, tente novamente.</Text>
            </Box>
          )
        })

        setLoading(false)
        return;
      }

      const coords = await getCoords(item.state?.name ?? '', item.address1 ?? '', item.address2 ?? '', item.zipCode ?? '', item.city?.name ?? '')
      const geoHash = getGeoHash(coords)

      // update with new data
      await updateDoc<Item>(existingItemRef, {
        name: item.name,
        nameLowerCase: item.name.replaceAll("'", '').replaceAll('"', '').toLowerCase(),
        description: item.description.trim(),

        logo: !isNil(item.logo) ? item.logo.trim().length > 0 ? item.logo.trim() : null : null,
        banner: !isNil(item.banner) ? item.banner.trim().length > 0 ? item.banner.trim() : null : null,
        slides: item.slides,
        phone: !isNil(item.phone) ? item.phone.trim().length > 0 ? item.phone.trim() : null : null,
        sms: !isNil(item.sms) ? item.sms.trim().length > 0 ? item.sms.trim() : null : null,
        whatsapp: !isNil(item.whatsapp) ? item.whatsapp.trim().length > 0 ? item.whatsapp.trim() : null : null,
        email: !isNil(item.email) ? item.email.trim().length > 0 ? item.email.trim() : null : null,
        website: !isNil(item.website) ? item.website.trim().length > 0 ? item.website.trim() : null : null,

        facebook: !isNil(item.facebook) ? item.facebook.trim().length > 0 ? item.facebook.trim() : null : null,
        instagram: !isNil(item.instagram) ? item.instagram.trim().length > 0 ? item.instagram.trim() : null : null,
        youtube: !isNil(item.youtube) ? item.youtube.trim().length > 0 ? item.youtube.trim() : null : null,

        address1: !isNil(item.address1) ? item.address1.trim().length > 0 ? item.address1.trim() : null : null,
        address2: !isNil(item.address2) ? item.address2.trim().length > 0 ? item.address2.trim() : null : null,
        zipCode: !isNil(item.zipCode) ? item.zipCode.trim().length > 0 ? item.zipCode.trim() : null : null,
        coords,
        geoHash,

        updatedAt: moment().utc().toDate(),
        
        state: item.state,
        city: item.city,
        category: item.category,
        subCategories: item.subCategories ?? [],
      })

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.success[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Parabéns! O item foi approvado com sucesso e já deve aparecer listado na categoria(s) selecionada(s)!</Text>
          </Box>
        )
      })

      await removeItemForApproval()

      setLoading(false)
      refreshItem(existingItemRef, item.snapshot.ref)
      return
    } catch (e) {
      console.log('APPROVE REQUESTED CHANGE - ERROR')
      console.error(e)

      toast.show({
        placement: 'bottom',
        render: () => (
          <Box bg={theme.colors.error[800]} px={4} py={2} rounded="full" mb={4} color='white'>
            <Text color={'white'}>Ops! Ocorreu um erro ao tentar aprovar o Item. Por favor, tente novamente.</Text>
          </Box>
        )
      })

      try {
        Analytics.logEvent('item_actionbutton_error', {
          method: 'updateItem',
          itemId: item.id,
          authorId: item.authorId,
          adminId: userData.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      setLoading(false)
      return
    }
  }, [item, userData, toast, removeItemForApproval, refreshItem, fetchAuthorData])

  useEffect(() => {
    try {
      Location.setGoogleApiKey(Constants.manifest?.extra?.googleApiKey)
    } catch (e) {
      console.error(e)

      try {
        Analytics.logEvent('edit_item_error', {
          method: 'setGoogleApiKey',
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  const actionIcons: IActionIcon[] = [
    {
      name: 'Aprovar',
      iconName: 'mci/check',
      action: () => {
        if (item.mode === 'new') {
          createItem()
          return
        }

        if (item.mode === 'change') {
          saveRequestedChanges()
          return
        }
      },
      condition: userData.role === 'admin' && ['new', 'change'].includes(item.mode),
      color: theme.colors.info[800]
    },
    {
      name: 'Reprovar',
      iconName: 'mi/block',
      action: () => {},
      condition: userData.role === 'admin' && ['new', 'change'].includes(item.mode),
      color: theme.colors.error[800]
    },
    {
      name: 'Estatísticas',
      iconName: 'ant/piechart',
      action: () => {
        navigation.push('itemstatistics', {
          id: item.id
        })
      },
      // condition: (userData.role === 'admin' || !isNil(permission?.level)) && ['change', 'item'].includes(item.mode)
      condition: false
    },
    {
      name: 'Editar',
      iconName: 'ant/edit',
      action: () => {
        navigation.push('edititem', {
          id: item.id,
          mode: item.mode
        })
      },
      condition: userData.role === 'admin' || (['item', 'change'].includes(item.mode) && !isNil(permission?.level)) || (item.mode === 'new' && item.authorId === userData.id)
    },
    {
      name: 'Clonar',
      iconName: 'fa5/clone',
      action: () => {
        navigation.push('newitem', {
          id: item.id
        })
      },
      condition: userData.role === 'admin' || (['item', 'change'].includes(item.mode) && !isNil(permission?.level)) || (item.mode === 'new' && item.authorId === userData.id)
    },
    {
      name: 'Desativar',
      iconName: 'ant/lock1',
      action: () => {
        setAlertDialogOpen(true)
      },
      condition: statusAction === 'deactivate',
      color: theme.colors.error[800],
    },
    {
      name: 'Ativar',
      iconName: 'ant/unlock',
      action: () => {
        setAlertDialogOpen(true)
      },
      condition: statusAction === 'activate',
      color: theme.colors.success[800]
    },
    {
      name: 'Excluir',
      iconName: 'ant/delete',
      action: () => {
        setAlertDialogOpen(true)
      },
      condition: statusAction === 'delete' && userData.role === 'user',
      color: theme.colors.error[800]
    }
  ].filter(actionIcon => actionIcon.condition)

  const marginBottom = !noMarginBottom ? 2 : 0

  if (actionIcons.length === 0) {
    return null
  }

  return (
    <HStack space={2} justifyContent='center' alignItems='center' flexWrap={'wrap'} height='full'>
      {actionIcons.map(actionIcon => {
        if (!showLabels) {
          return (
            <IconButton
              variant={'solid'}
              rounded='full'
              bgColor={actionIcon.color ?? theme.colors.primary[800]}
              key={actionIcon.name}
              onPress={actionIcon.action}
              marginBottom={marginBottom}
              icon={getIcon(actionIcon.iconName, {size: 12, color: theme.colors.white}) ?? undefined}
            />
          )
        }

        return (
          <Button
            variant={'solid'}
            rounded='full'
            bgColor={actionIcon.color ?? theme.colors.primary[800]}
            key={actionIcon.name}
            onPress={actionIcon.action}
            marginBottom={marginBottom}
            leftIcon={getIcon(actionIcon.iconName, {size: 16, color: theme.colors.white}) ?? undefined}
            size={'xs'}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {actionIcon.name}
          </Button>
        )
      })}

      <AlertDialog leastDestructiveRef={cancelRef} isOpen={isAlertDialogOpen} onClose={handleAlertDialogClose}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Confirmar ação</AlertDialog.Header>
          <AlertDialog.Body>
            {`Deseja realmente ${statusAction === 'activate' ? 'ativar' : statusAction === 'deactivate' ? 'desativar' : 'excluir'} este item?`}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button variant="unstyled" colorScheme="coolGray" onPress={handleAlertDialogClose} disabled={isLoading} ref={cancelRef}>
                Cancelar
              </Button>
              <Button colorScheme={statusAction === 'activate' ? 'success' : 'error'} isLoading={isLoading} onPress={handleAlertDialogConfirm}>
                {statusAction === 'activate' ? 'Ativar' : statusAction === 'deactivate' ? 'Desativar' : 'Excluir'}
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </HStack>
  )
}

export default memo(ActionIconButtons)
