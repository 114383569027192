import { collection, CollectionReference, documentId, DocumentReference, getDoc, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { isNil } from 'lodash';
import { Fab, FlatList, HStack, Icon, useTheme, View } from 'native-base';
import React, { FC, memo, useState, useEffect, useCallback, useMemo } from 'react';
import ListItem from '../../../components/ListItem';
import useAuth from '../../../hooks/useAuth';
import Item, { ItemMode, ItemStatus } from '../../../models/Item.class';
import {Ionicons} from '@expo/vector-icons'
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { DashboardStackParamList } from '../navigators/DashboardNavigator';
import * as Analytics from 'expo-firebase-analytics'
import useDebounce from '../../../hooks/useDebounce';
import SearchBar from '../../../components/SearchBar';
import { FirestoreCollections } from '../../../models/FirestoreCollections.enum';
import { useDispatch } from 'react-redux';
import { setPermissions } from '../../../redux/auth';
import FilterByStatus from '../../../components/FilterByStatus';
import FilterByMode from '../../../components/FilterByMode';

export interface ItemsSortOptions {
  all: string
  [ItemStatus.ACTIVE]: string
  [ItemStatus.INACTIVE]: string
  [ItemStatus.PENDING_APPROVAL]: string
  [ItemStatus.REJECTED]: string
}

export interface ItemsModeSortOptions {
  all: string
  [ItemMode.ITEM]: string
  [ItemMode.NEW]: string
  [ItemMode.CHANGE]: string
}

const Home: FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { userData } = useAuth()
  const firestore = getFirestore()
  const navigation = useNavigation<NativeStackNavigationProp<DashboardStackParamList>>()

  const [ items, setItems ] = useState<Item[] | undefined>();
  const [filteredItems, setFilteredItems] = useState<Item[] | null>()

  const [ searchQuery, setSearchQuery ] = useState('');

  const [itemsFilterType, setItemsFilterType] = useState<ItemStatus | 'all'>('all')
  const filterOptions = useMemo<ItemsSortOptions>(() => ({
    all: 'Todos',
    [ItemStatus.ACTIVE]: 'Ativos',
    [ItemStatus.INACTIVE]: 'Inativos',
    [ItemStatus.PENDING_APPROVAL]: 'Em Análise',
    [ItemStatus.REJECTED]: 'Reprovados',
  }), [])

  const [itemsFilterMode, setItemsFilterMode] = useState<ItemMode | 'all'>('all')
  const filterModeOptions = useMemo<ItemsModeSortOptions>(() => ({
    all: 'Todos',
    [ItemMode.ITEM]: 'Publicados',
    [ItemMode.NEW]: 'Novos',
    [ItemMode.CHANGE]: 'Alterações',
  }), [])

  const { execute: debouncedSearch } = useDebounce(300, (term: string) => {
    if (!isNil(term)) {
      setSearchQuery(term.trim().toLowerCase())
    }
  });

  const handleSearchQueryChange = useCallback((query: string) => {
    debouncedSearch(query)
  }, [debouncedSearch])

  const handleAddButtonClick = useCallback(() => {
    if (navigation) {
      navigation.push('newitem')
    }
  }, [navigation])

  const handleRefreshItem = useCallback(async (itemRef: DocumentReference<Item>, replaceRef?: DocumentReference<Item>) => {
    if (!items) {
      return
    }

    const newItems = Array.from(items)

    let itemIndex: number;

    if (!isNil(replaceRef)) {
      itemIndex = newItems.findIndex(item => item.snapshot.ref.path === replaceRef.path)
    } else {
      itemIndex = newItems.findIndex(item => item.snapshot.ref.path === itemRef.path)
    }

    try {
      const itemDoc = await getDoc(itemRef)
      if (!itemDoc.exists()) {
        throw new Error('ITEM_NOT_FOUND')
      }

      const refreshedItem = new Item(itemDoc)

      if (itemIndex >= 0) {
        newItems[itemIndex] = refreshedItem
      } else {
        newItems.push(refreshedItem)
      }

      setItems(newItems)
    } catch (e) {
      console.error(e)
      
      if (itemIndex >= 0) {
        newItems.splice(itemIndex, 1)
        setItems(newItems)
      }

      try {
        Analytics.logEvent('refreshItem_error', {
          itemId: itemRef.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }, [items])

  useFocusEffect(useCallback(() => {
    if (firestore && userData) {
      ;(async () => {
        const itemsForApprovalCollection = collection(firestore, FirestoreCollections.ITEMS_FOR_APPROVAL) as CollectionReference<Item>
        const requestedChangesCollection = collection(firestore, FirestoreCollections.REQUESTED_CHANGES) as CollectionReference<Item>
        const itemsCollection = collection(firestore, FirestoreCollections.ITEMS) as CollectionReference<Item>

        const fetchedItems: Item[] = []
        const fetchedItemIDs: string[] = []

        if (userData.role === 'admin' || userData.role === 'mod') {
          for (let collection of [itemsForApprovalCollection, requestedChangesCollection, itemsCollection]) {
            try {
              const docs = await getDocs(query(collection, orderBy('name', 'asc')))

              docs.forEach(doc => {
                if (!fetchedItemIDs.includes(doc.id)) {
                  fetchedItemIDs.push(doc.id)
                  fetchedItems.push(new Item(doc, collection.path.includes(FirestoreCollections.ITEMS_FOR_APPROVAL) ? ItemMode.NEW : collection.path.includes(FirestoreCollections.REQUESTED_CHANGES) ? ItemMode.CHANGE : ItemMode.ITEM))
                }
              })
            } catch (e) {
              console.error(e)

              try {
                Analytics.logEvent('home_error', {
                  method: 'fetch_admin_mod_items',
                  userId: userData.id,
                  email: userData.email,
                  role: userData.role,
                  name: (e as Error).name ?? 'Error',
                  message: (e as Error).message ?? 'none',
                  code: (e as any).code ?? 'none'
                })
              } catch (e) {
                console.error(e)
              }
            }
          }
        } else {
          try {
            const newItems = await getDocs(query(itemsForApprovalCollection, where('authorId', '==', userData.id)))
            newItems.forEach(doc => fetchedItems.push(new Item(doc, ItemMode.NEW)))
          } catch (e) {
            console.error(e)

            try {
              Analytics.logEvent('home_error', {
                method: 'fetch_user_new_items',
                userId: userData.id,
                email: userData.email,
                role: userData.role,
                name: (e as Error).name ?? 'Error',
                message: (e as Error).message ?? 'none',
                code: (e as any).code ?? 'none'
              })
            } catch (e) {
              console.error(e)
            }
          }

          const permissions = await userData.fetchPermissions()
          dispatch(setPermissions(permissions))
          const permissionItemIds = permissions.map(permission => permission.itemRef.id)

          if (permissionItemIds.length > 0) {
            try {
              const requestedChanges = await getDocs(query(requestedChangesCollection, where(documentId(), 'in', permissionItemIds)))
              requestedChanges.forEach(doc => fetchedItems.push(new Item(doc, ItemMode.CHANGE)))
            } catch (e) {
              console.error(e)

              try {
                Analytics.logEvent('home_error', {
                  method: 'fetch_requested_changes',
                  userId: userData.id,
                  email: userData.email,
                  role: userData.role,
                  name: (e as Error).name ?? 'Error',
                  message: (e as Error).message ?? 'none',
                  code: (e as any).code ?? 'none'
                })
              } catch (e) {
                console.error(e)
              }
            }

            try {
              const items = await getDocs(query(itemsCollection, where(documentId(), 'in', permissionItemIds)))
              items.forEach(doc => {
                if (!fetchedItemIDs.includes(doc.id)) {
                  fetchedItemIDs.push(doc.id)
                  fetchedItems.push(new Item(doc))
                }
              })
            } catch (e) {
              console.error(e)

              try {
                Analytics.logEvent('home_error', {
                  method: 'fetch_items',
                  userId: userData.id,
                  email: userData.email,
                  role: userData.role,
                  name: (e as Error).name ?? 'Error',
                  message: (e as Error).message ?? 'none',
                  code: (e as any).code ?? 'none'
                })
              } catch (e) {
                console.error(e)
              }
            }
          }
        }

        setItems(fetchedItems)
      })()
    }

    return () => {
      setItems([])
      dispatch(setPermissions(undefined))
    }
  }, [firestore, userData, dispatch]))

  useEffect(() => {
    if (items) {
      const newFilteredItems = items.reduce((result: Item[], item: Item) => {
        if (
          (
            searchQuery &&
            searchQuery.trim().length > 0 &&
            !item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.addressLine.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.email?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.instagram?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.facebook?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.phone?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.sms?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.whatsapp?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.youtube?.toLowerCase().includes(searchQuery.toLowerCase()) &&
            !item.zipCode?.toLowerCase().includes(searchQuery.toLowerCase())
          )
          || (
            itemsFilterType !== 'all' 
            && item.status !== itemsFilterType
          )
          || (
            itemsFilterMode !== 'all' 
            && item.mode !== itemsFilterMode
          )
        ) {
          return result
        }

        result.push(item)

        return result
      }, [])

      setFilteredItems(newFilteredItems)
    }
  }, [items, searchQuery, itemsFilterType, itemsFilterMode])

  return (
    <View flex={1} width='full' overflow='hidden' position='relative'>
      <HStack space={2} p={2} justifyContent='center' alignItems='center' w='full'>
        <SearchBar placeholder={`Buscar item`} flex={1} onChange={handleSearchQueryChange} />
        <FilterByMode itemsFilterMode={itemsFilterMode} filterModeOptions={filterModeOptions} setItemsFilterMode={setItemsFilterMode} />
        <FilterByStatus itemsFilterType={itemsFilterType} filterOptions={filterOptions} setItemsFilterType={setItemsFilterType} />
      </HStack>
      
      <FlatList
        paddingY={4}
        paddingBottom={24}
        data={filteredItems}
        keyExtractor={item => item.snapshot.ref.path}
        renderItem={({item}) => (
          <ListItem item={item} userData={userData} refreshItem={handleRefreshItem} />
        )}
      />
      <Fab
        renderInPortal={false}
        placement="bottom-right"
        size="md"
        icon={<Icon as={Ionicons} name='md-add-sharp' color="white" size="md" />}
        onPress={handleAddButtonClick}
      />
    </View>
  );
}

export default memo(Home);
