import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Permission from '../../models/Permission.class';

import initialState, { AuthState } from './state';

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPermissions(state: AuthState, action: PayloadAction<Permission[] | undefined>) {
      state.permissions = action.payload
    }
  },
  extraReducers: (builder) => {}
});

export const { setPermissions } = authSlice.actions;

export default authSlice.reducer;
