export const sortByOrderASC = (a: any, b: any) => {
  if (a.order < b.order) {
    return -1
  } else if (a.order > b.order) {
    return 1
  } return 0
}

export const sortByOrderDESC = (a: any, b: any) => {
  if (a.order < b.order) {
    return 1
  } else if (a.order > b.order) {
    return -1
  } return 0
}

export const sortByNameASC = (a: any, b: any) => {
  if (a.name < b.name) {
    return -1
  } else if (a.name > b.name) {
    return 1
  } return 0
}

export const sortByNameDESC = (a: any, b: any) => {
  if (a.name < b.name) {
    return 1
  } else if (a.name > b.name) {
    return -1
  } return 0
}

export const sortByTitleASC = (a: any, b: any) => {
  if (a.title < b.title) {
    return -1
  } else if (a.title > b.title) {
    return 1
  } return 0
}

export const sortByTitleDESC = (a: any, b: any) => {
  if (a.title < b.title) {
    return 1
  } else if (a.title > b.title) {
    return -1
  } return 0
}
