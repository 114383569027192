import { Image, Spinner, useTheme, View, VStack } from 'native-base';
import React, { FC, memo } from 'react';

const SplashScreen: FC = () => {
  const theme = useTheme()

  return (
    <View
      flex={1}
      overflow='hidden'
      backgroundColor={theme.colors.primary[800]}
      justifyContent='center'
      alignItems='center'
    >
      <VStack space={10}>
        <Image src={require('../../assets/web-icon.png')} resizeMode='contain' width={120} height={120} />

        <Spinner size="lg" color="white" />
      </VStack>
    </View>
  );
}

export default memo(SplashScreen);
