import { NativeStackHeaderProps } from "@react-navigation/native-stack"
import { Button, Heading, HStack, Icon, IconButton, Image, useTheme, Text } from "native-base"
import React, { FC } from "react"
import { getIcon } from "../helpers/icon.helper"
import { Platform } from "react-native"
import { MaterialIcons } from '@expo/vector-icons';
import useAuth from "../hooks/useAuth"

const AppHeader: FC<NativeStackHeaderProps> = ({back, options, route, navigation}) => {
  const theme = useTheme()
  const { user, signOut } = useAuth()

  if (route.name === 'signin') {
    return null
  }

  if (route.name === 'signup') {
    return (
      <HStack space={2} alignItems="center" bgColor='transparent' p={2}>
        <HStack flex={1} space={2} alignItems='center'>
          <IconButton
            variant='unstyled'
            onPress={() => {
              if (back && navigation.canGoBack()) {
                navigation.goBack()
              } else {
                navigation.navigate('signin')
              }
            }}
            icon={
              getIcon(`mi/${Platform.OS === 'ios' ? 'arrow-back-ios' : 'arrow-back'}`, {size: 20, color: theme.colors.primary[800]}) ?? undefined
            }
          />
        </HStack>
      </HStack>
    )
  }

  return (
    <HStack space={2} alignItems="center" bgColor={theme.colors.white} p={2}>
      <HStack flex={1} space={2} alignItems='center'>
        {back && navigation.canGoBack() ? (
          <IconButton
            variant='unstyled'
            onPress={() => navigation.goBack()}
            icon={
              getIcon(`mi/${Platform.OS === 'ios' ? 'arrow-back-ios' : 'arrow-back'}`, {size: 20, color: theme.colors.primary[800]}) ?? undefined
            }
          />
        ) : (
          <Image src={require('../assets/web-icon.png')} size={8} />
        )}
        <Heading size="xs" color={theme.colors.teal[800]} isTruncated>{(options.headerTitle as string) ?? 'BrasilAmericano'}</Heading>
      </HStack>

      <HStack w={'135px'} space={2} alignItems='center' justifyContent={'flex-end'}>
        {user ? (
          <Button
            size="xs"
            leftIcon={<Icon name="logout" as={MaterialIcons} size="xs" color="white" />} 
            onPress={() => signOut()}
          >
            Sair
          </Button>
        ) : null}
      </HStack>
    </HStack>
  )
}

export default AppHeader
