import { FormControl, Select, theme, CheckIcon, Icon } from 'native-base';
import { FC, memo } from 'react';
import { Category } from '../../models/Category.class';
import {SimpleLineIcons} from '@expo/vector-icons'

interface SelectCategoryProps {
  categories: Category[] | undefined;
  setSelectedCategoryId: (id: string) => void;
  isInvalid?: boolean;
  selectedValue?: string;
}

const SelectCategory: FC<SelectCategoryProps> = ({isInvalid, selectedValue, setSelectedCategoryId, categories}) => {
  return (
    <FormControl flex={1} isRequired isInvalid={isInvalid}>
      <FormControl.Label>Categoria</FormControl.Label>
      <Select
        variant='rounded'
        selectedValue={selectedValue}
        flex={1}
        onValueChange={itemValue => setSelectedCategoryId(itemValue)}
        _selectedItem={{
          bg: theme.colors.primary[800],
          leftIcon: <CheckIcon size={2} />
        }}
        dropdownIcon={
          <Icon
            size={3}
            color={theme.colors.primary[800]}
            as={SimpleLineIcons}
            name="arrow-down"
            marginRight={4}
          />
        }
      >
        <Select.Item label={'Selecionar Categoria'} value={''} />
        {categories ? categories.map(category => (
          <Select.Item key={category.name} label={category.name} value={category.id} />
        )) : <></>}
      </Select>
      <FormControl.ErrorMessage>
        Por favor, selecione uma categoria
      </FormControl.ErrorMessage>
    </FormControl>
  );
}

export default memo(SelectCategory);
