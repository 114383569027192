import { Icon, IconButton, Input, useTheme } from "native-base"
import { FC, memo, useCallback, useState } from "react"
import {EvilIcons} from '@expo/vector-icons'
import { ColorType } from "native-base/lib/typescript/components/types"

interface SearchBarProps {
  placeholder?: string
  flex?: number
  onChange: (query: string) => void,
  bgColor?: ColorType,
}

const SearchBar: FC<SearchBarProps> = ({placeholder, flex, onChange, bgColor}) => {
  const theme = useTheme()

  const [text, setText] = useState<string>('')

  const handleOnChange = useCallback((query: string) => {
    setText(query)
    onChange(query.trim())
  }, [onChange])

  const handleClearClick = useCallback(() => {
    handleOnChange('')
  }, [handleOnChange])

  return (
    <Input
      bgColor={bgColor}
      value={text}
      flex={flex}
      variant='rounded'
      size={'sm'}
      placeholder={placeholder ?? 'Buscar'}
      width={'full'}
      autoComplete='off'
      onChangeText={handleOnChange}
      InputLeftElement={
        <Icon
          marginLeft={2}
          size={5}
          color={theme.colors.primary[800]}
          as={EvilIcons}
          name="search"
        />
      }
      InputRightElement={
        <IconButton
          variant={'unstyled'}
          onPress={handleClearClick}
          icon={
            <Icon
              size={5}
              color={theme.colors.primary[800]}
              as={EvilIcons}
              name="close"
            />
          }
        />
      }
    />
  )
}

export default memo(SearchBar)
