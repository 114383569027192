import { FormControl, VStack, TextArea } from 'native-base';
import { Key } from 'react';
import { FC, memo } from 'react';

interface DescriptionTextAreaProps {
  fieldKey?: Key | null | string;
  setAttribute: (attr: string, value: any) => void;
  initialValue?: string;
  value?: string;
}

const DescriptionTextArea: FC<DescriptionTextAreaProps> = ({setAttribute, fieldKey, initialValue, value}) => {
  return (
    <FormControl>
      <VStack>
        <FormControl.Label>Descrição</FormControl.Label>
        <TextArea key={fieldKey} rounded='lg' onChangeText={text => setAttribute('description', text)} autoCompleteType="off" defaultValue={initialValue} value={value} />
      </VStack>
    </FormControl>
  );
}

export default memo(DescriptionTextArea);
