import { FormControl, Select, theme, CheckIcon, Icon } from 'native-base';
import { FC, memo } from 'react';
import { SimpleLineIcons } from '@expo/vector-icons';
import City from '../../models/City.class';

interface SelectCityProps {
  isRequired: boolean;
  isInvalid: boolean;
  selectedValue: string;
  setSelectedCityId: (value: string) => void;
  cities: City[] | undefined;
}

const SelectCity: FC<SelectCityProps> = ({isRequired, isInvalid, selectedValue, setSelectedCityId, cities}) => {
  return (
    <FormControl isRequired={isRequired} flex={1} isInvalid={isInvalid}>
      <FormControl.Label>Cidade</FormControl.Label>
      <Select
        variant='rounded'
        selectedValue={selectedValue}
        flex={1}
        onValueChange={itemValue => setSelectedCityId(itemValue)}
        _selectedItem={{
          bg: theme.colors.primary[800],
          leftIcon: <CheckIcon size={2} />
        }}
        dropdownIcon={
          <Icon
            size={3}
            color={theme.colors.primary[800]}
            as={SimpleLineIcons}
            name="arrow-down"
            marginRight={4}
          />
        }
      >
        <Select.Item label={'Selecionar Cidade'} value={''} />
        <Select.Item label={'Adicionar Nova Cidade'} value={'other'} />
        {cities ? cities.map(city => (
          <Select.Item key={city.name} label={city.name} value={city.id} />
        )) : <></>}
      </Select>
      <FormControl.ErrorMessage>
        Cidade é obrigatória quando um endereço for preenchido.
      </FormControl.ErrorMessage>
    </FormControl>
  );
}

export default memo(SelectCity);
