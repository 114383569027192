import { isNil } from 'lodash';
import { DocumentSnapshot, DocumentReference, getDoc } from "firebase/firestore";
import { FirestoreSnapshot } from "./FirestoreSnapshot.class";
import * as Analytics from 'expo-firebase-analytics'
import { User } from "./User.class";
import Item from './Item.class';

export type PermissionType = 'owner' | 'admin' | 'mod'

export default class Permission extends FirestoreSnapshot<Permission> {
  itemRef: DocumentReference<Item>
  userRef: DocumentReference<User>
  level: PermissionType
  createdAt: Date
  createdBy: DocumentReference<User> | null

  constructor(doc: DocumentSnapshot<Permission>) {
    super(doc)
    
    const data = doc.data()!
    
    this.itemRef = data.itemRef
    this.userRef = data.userRef
    this.level = data.level
    this.createdAt = data.createdAt
    this.createdBy = data.createdBy
  }

  async getItem(): Promise<Item | null> {
    try {
      const itemDoc = await getDoc(this.itemRef)

      if (!itemDoc.exists()) {
        throw new Error(`ITEM_NOT_FOUND`)
      }

      return new Item(itemDoc)
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('permission_error', {
          method: 'getItem',
          id: this.id,
          itemId: this.itemRef.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }
      
      return null
    }
  }

  async getUser(): Promise<User | null> {
    try {
      const userDoc = await getDoc(this.userRef)

      if (!userDoc.exists()) {
        throw new Error(`USER_NOT_FOUND`)
      }

      return new User(userDoc)
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('permission_error', {
          method: 'getUser',
          id: this.id,
          userId: this.userRef.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      return null
    }
  }

  async getCreator(): Promise<User | null> {
    if (isNil(this.createdBy)) {
      return null
    }

    try {
      const userDoc = await getDoc(this.createdBy)

      if (!userDoc.exists()) {
        throw new Error(`USER_NOT_FOUND`)
      }

      return new User(userDoc)
    } catch (e) {
      console.error(e)
      
      try {
        Analytics.logEvent('permission_error', {
          method: 'getCreator',
          id: this.id,
          userId: this.createdBy.id,
          name: (e as Error).name ?? 'Error',
          message: (e as Error).message ?? 'none',
          code: (e as any).code ?? 'none'
        })
      } catch (e) {
        console.error(e)
      }

      throw e
    }
  }
}
