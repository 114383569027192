import { FC, memo } from 'react';
import { Menu, theme, Button } from 'native-base';
import { getIcon } from '../helpers/icon.helper';
import { ItemStatus } from '../models/Item.class';
import { ItemsSortOptions } from '../views/protected/views/Home';

interface FilterByStatusProps {
  itemsFilterType: ItemStatus | 'all',
  setItemsFilterType: React.Dispatch<React.SetStateAction<ItemStatus | "all">>,
  filterOptions: ItemsSortOptions
}

const FilterByStatus: FC<FilterByStatusProps> = ({itemsFilterType, filterOptions, setItemsFilterType}) => {
  return (
    <Menu
      width='150'
      placement='left'
      trigger={triggeProps => (
        <Button
          size='sm'
          borderWidth={1}
          borderColor={theme.colors.coolGray[200]}
          _text={{
            color: theme.colors.coolGray[600]
          }}
          variant='unstyled'
          rounded='full'
          leftIcon={
            getIcon('mi/filter-list', {size: 18, color: theme.colors.primary[800]})!
          }
          rightIcon={
            getIcon('sli/arrow-down', {size: 12, color: theme.colors.primary[800]})!
          }
          {...triggeProps}
        >
          {filterOptions[itemsFilterType]}
        </Button>
      )}
    >
      {Object.keys(filterOptions).map(sortType => (
        <Menu.Item key={sortType} _text={{fontSize: 'xs'}} onPress={() => setItemsFilterType(sortType as ItemStatus)}>
          {filterOptions[sortType as keyof ItemsSortOptions]}
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default memo(FilterByStatus);
